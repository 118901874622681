import Vue from 'vue'
import jwtDecode from "jwt-decode";

Vue.prototype.listRoles = {
    // return {
    solicitudes: {
        lista: "ROLE_SOLICITUD_LISTAR",
        crear: "ROLE_SOLICITUD_CREAR",
        editar: "ROLE_SOLICITUD_EDITAR",
        ver: "ROLE_SOLICITUD_VER",
        enviar_aprobar:'ROLE_SOLICITUD_ENVIAR_APROBACION',
        
        jefatura_lista_aprobar: 'ROLE_SOLICITUD_JEFATURA_APROBACION_LISTAR',
        rrhh_lista_aprobar: 'ROLE_SOLICITUD_RRHH_APROBACION_LISTAR',
        ufi_lista_aprobar: 'ROLE_SOLICITUD_UFI_APROBACION_LISTAR',
        despacho_lista_aprobar: 'ROLE_SOLICITUD_DESPACHO_APROBACION_LISTAR',
        dinarh_lista_aprobar: 'ROLE_SOLICITUD_DINARH_APROBACION_LISTAR',

        jefatura_evaluar: 'ROLE_SOLICITUD_JEFATURA_EVALUAR',
        rrhh_evaluar: 'ROLE_SOLICITUD_RRHH_EVALUAR',
        ufi_evaluar: 'ROLE_SOLICITUD_UFI_EVALUAR',
        despacho_evaluar: 'ROLE_SOLICITUD_DESPACHO_EVALUAR',
        dinarh_evaluar: 'ROLE_SOLICITUD_DINARH_EVALUAR',

        evaluar:'ROLE_SOLICITUD_EVALUAR',

        eliminar: "DELETE_SOLICITUD",
        aprobar: "APROBAR_SOLICITUD",
    },
    contrato: {
        lista: "VIEW_LIST_CONTRATO",
        crear: "CREATE_CONTRATO",
        ver: "VIEW_CONTRATO",
        editar: "EDIT_CONTRATO",
        eliminar: "DELETE_CONTRATO",
    }
    // }
}

Vue.prototype.validRol = (rol) => {
    function esConvertibleDeJSON(variable) {
        try {
            return JSON.parse( variable)
        } catch (error) {
            return null;
        }
    }
    const jsonRoles = localStorage.getItem('roles')
    
    const roles = esConvertibleDeJSON(jsonRoles) ?? null
    /*if (!roles) return true //! IMPORTANTE retornar "false" cuando ya estén los roles.;
    return roles.some((item) => item === rol);*/
    return true;
}