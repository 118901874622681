<template>
    <v-dialog v-model="estado" persistent max-width="700px">
      <v-card class="py-5">
        <v-card-title style="word-break: normal;">
          <v-row class="pa-1 flex-column-reverse flex-sm-row">
            <v-col cols="12" sm="10" class="offset-md-1 offset-sm-1 offset-lg-1 offset-xl-1 mt-2 py-0 px-1 py-md-2 px-md-2">
              <div class="text-center">
                <div
                    class="d-flex justify-center justify-end text-h4 font-weight-bold BlueMinsalDark text-md-h4 text-sm-h4 text-lg-h4 text-xl-h4"
                >
                  {{ titulo }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="1" class="mt-mb-0 mt-sm-0 mt-1 text-end pa-0 pt-5 px-2 py-sm-2 pr-sm-5">
              <v-btn icon @click="$emit('cancel');estado = false;">
                <v-icon class="d-flex justify-left d-flex m-2" :size="$vuetify.breakpoint.xsOnly ? '40px' : '40px'">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
            <!-- <div class="row d-flex justify-center">
              <p class="text-h4 titulo font-weight-black mt-5">
                <v-icon color="#efa525" :class="!$vuetify.breakpoint.xs ? 'd-flex justify-left ':'d-flex m-2'" :size="$vuetify.breakpoint.xsOnly ? '50px' : '70px'">
                  mdi-alert-circle-outline
                </v-icon>
              </p>
            </div> -->
            <div
              class="row d-flex justify-center text-center font-weight-black mt-5"
            >
              <p class="text-h5 primary--text">{{ pregunta }}</p>
            </div>
            <v-row justify="center">
              <v-col cols="9 text-center">
                <div class="text-md-h5 text-sm-h5 text-lg-h4 text-xl-h4 text-h4 primary--text"
                  style="font-weight: medium !important;">
                  <span style="font-weight: medium !important;">¡No podrá deshacer este cambio!</span>
                </div>
              </v-col>
            </v-row>
            <div
              class="row d-flex justify-center text-center font-weight-black mt-5"
            >
              <slot name="body">
                <h3 v-if="mostrarEstado">
                  Se cambiará su estado a
                  {{ estadoCambiar ? "activo" : "inactivo" }}
                </h3>
              </slot>
            </div>
        </v-card-text>
        <v-card-actions>
          <v-row :justify="'center'">
            <v-col cols="12" sm="4" class="d-flex justify-center">
              <v-btn
                max-width="66%"
                color="primary"
                rounded
                x-large
                outlined
                class="text-capitalize font-weight-black"
                @click="
                  $emit('cancel');
                  estado = false;
                "
              >
                Cancelar
              </v-btn>
            </v-col>

            <v-col cols="12" sm="4" class="d-flex justify-center">
              <v-btn
                max-width="66%"
                color="primary"
                class="text-capitalize font-weight-black"
                rounded
                x-large
                @click="$emit('submit'); estado=false"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <script>
  export default {
    name: "AppModalDelete",
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      estadoCambiar: {
        type: Boolean,
        required: true,
      },
      titulo: {
        type: String,
        required: true,
      },
      pregunta: {
        type: String,
        required: true,
      },
      mostrarEstado: {
        type: Boolean,
        default: true,
      }
    },

    emits: ["update:modelValue"],
    data() {
      return {};
    },
    watch: {},
    computed: {
      estado: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
    },
    methods: {},
  };
  </script>
  <style>
  .modal__skyblue--text {
    color: #1C1E4D;
  }
  .modal_skyblue--background {
    background-color: #1C1E4D;
  }
  .v-dialog {
    border-radius: 75px;
  }
  </style>
