import { http_client } from "@/plugins/http_client";

const url = "/api/v1/mercado-laboral";

const url1 = "/api/v1";
const getInstituciones = async (params = {}) =>
  await http_client(`${url}/instituciones`, params, "get");
const getInstitucionesGeneral = async (params = {}) =>
  await http_client(`${url1}/instituciones`, params, "get");
const getProcesos = async (params = {}) =>
  await http_client(`${url1}/proceso-contratacion`, params, "get");
const getFases = async (idProceso, params = {}) =>
  await http_client(
    `${url1}/fase-aprobacion/${idProceso}/fases`,
    params,
    "get"
  );
const updateProcesos = async (idProceso, params) =>
  await http_client(
    `${url1}/asignar_estable_proceso/${idProceso}`,
    params,
    "put"
  );
const getPersonasFase = async (params) =>
  await http_client(
    `${url1}/proceso-contratacion/autorizar/unidad-aprobacion`,
    params,
    "get"
  );
const getEstadosFase = async (params) =>
  await http_client(`${url1}/estados_fase`, params, "get");
const getEstadosFaseSolicitud = async (params) =>
  await http_client(`${url1}/estados_fase/estados_solicitud`, params, "get");
const getPuestosbyEstab = async (idEstab, params = {}) =>
  await http_client(`${url1}/puestos/${idEstab}`, params, "get");
const updateEstadoFaseContrac = async (params = {}) =>
  await http_client(
    `${url1}/proceso-contratacion/estado-contratacion-fase`,
    params,
    "put"
  );
const DeleteDocumentosCandidato = async (idDoc, params = {}) =>
  await http_client(
    `${url1}/contratacion/documento/${idDoc}`,
    params,
    "delete"
  );
const CreateDocumentosCandidato = async (idContr, params = {}) =>
  await http_client(
    `${url1}/contratacion/documento/${idContr}`,
    params,
    "post"
  );
const visualizarPdfPlantilla = async (params = {}) =>
  await http_client(
    `${url1}/contratacion/documento/previsualizar`,
    params,
    "get"
  );
const visualizarPdfPlantillaCorrelativo = async (params = {}) =>
  await http_client(
    `${url1}/plantilla-correlativo/documento/previsualizar`,
    params,
    "get"
  );
const getCatalogos = async (catalogo, params = {}) =>
  await http_client(`${url1}/catalogos/${catalogo}`, params, "get");
const getCatalogo = async (catalogo, params = {}) =>
  await http_client(`${url1}/${catalogo}`, params, "get");
const getMunicipios = async (params = {}) =>
  await http_client(`${url1}/municipio`, params, "get");
const getDepartamentos = async (params = {}) =>
  await http_client(`${url1}/departamento`, params, "get");

export default {
  getInstituciones,
  getInstitucionesGeneral,
  getProcesos,
  getFases,
  updateProcesos,
  getPersonasFase,
  getEstadosFase,
  getEstadosFaseSolicitud,
  getPuestosbyEstab,
  updateEstadoFaseContrac,
  DeleteDocumentosCandidato,
  CreateDocumentosCandidato,
  visualizarPdfPlantilla,
  visualizarPdfPlantillaCorrelativo,
  getCatalogos,
  getCatalogo,
  getMunicipios,
  getDepartamentos,
};
