<template>
 <v-container>
  <app-dialog title="Enviar a autorización" v-model="dialog_autorizar">
    <template #body>
      <v-container>
        <v-row justify="center">
          <v-col cols="10 text-center">
            <div class="text-md-h4 text-sm-h6 text-lg-h4 text-xl-h4 text-h4 font-weight-bold primary--text">
              Se enviará la información <br/> del candidato para solicitar <br/> autorización
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="9 text-center">
            <div class="text-md-h6 text-sm-h6 text-lg-h5 text-xl-h5 text-h5 primary--text"
              style="font-weight: medium !important;">
              <span style="font-weight: medium !important;">¡No podrá deshacer este cambio!</span>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="11">
            <v-row class="d-flex">
              <v-col cols="12" md="12" sm="12" class="align-self-center">
                <data-table-documentos
                v-if="objeto1.items.length > 0"
                :items="objeto1.items"
                :headers="objeto1.headers"
                >
                <template #[`item.num_doc`]="{ item }">
                  <div style="margin: auto 50px; height: 45px; " class="mt-1" >
                    <v-text-field outlined dense v-model="form_numero_contrato[`numero_${item.id}`]"
                    :label="`Digite N°`" class="rounded-xl "></v-text-field>
                  </div>
                </template>
                <template #[`item.visualizacion`]="{ item }">
                  <div class="d-inline text-center" justify="center">
                    <div class="d-inline">
                      <v-tooltip bottom class="text-center">
                        <template v-slot:activator="{ on, attrs }">
                          <v-row justify="center">
                            <v-col>
                              <button @click="visualizarPdf(item)" icon>
                                <v-icon color="primary" align="center" dense>
                                  mdi-eye
                                </v-icon>
                              </button>
                            </v-col>
                          </v-row>
                        </template>
                        <span>Ver plantilla</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>
                </data-table-documentos>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-container>     
    </template>
    <template #actions>
      <v-container >
        <v-row justify="center" >
          <v-col cols="4" sm="4" md="3" lg="3" class="pr-5">
            <v-btn rounded outlined color="primary" class="btn-text" width="115"
              @click="dialog_autorizar = false; $parent.form_numero_contrato = {}">
              Cancelar
            </v-btn>
          </v-col>          
          <v-col cols="4" sm="4" md="3" lg="3">
            <v-btn rounded dark color="primary" class="btn-text" @click="generarDocumentos" width="115">
              Aceptar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </app-dialog>
  <v-dialog v-model="dialog_visualizar_archivo" max-width="900" persistent
              style="background-color: transparent; overflow-y: hidden !important;">
      <v-card style="border-radius: 25px !important;">
        <v-card-title style="word-break: normal;">
          <v-row class="pa-1">
            <v-col cols="10" class="offset-md-1 offset-sm-1 offset-lg-1 offset-xl-1 mt-2">
              <div
                  class="BlueMinsalDark text-md-h6 text-sm-h6 text-lg-h6 text-xl-h6 text-subtitle-1 font-weight-regular">
              </div>
            </v-col>
            <v-col cols="1" class="mt-mb-0 mt-sm-0 mt-1">
              <button @click="dialog_visualizar_archivo = false" icon>
                <v-icon align="center" :size="$vuetify.breakpoint.xsOnly ? '25px' : '40px'">
                  mdi-close
                </v-icon>
              </button>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="word-break: normal;">
          <vue-pdf-app style="height: 100vh;" ref="pdfApp" :config="config" :pdf="url_pdf"></vue-pdf-app>
        </v-card-text>
      </v-card>
  </v-dialog>
 </v-container>
</template>
<script>
import {mapMutations} from "vuex";
import VuePdfApp from "vue-pdf-app";

import DataTableDocumentos from "../views/contratos/components/DataTableDocumentos.vue";
export default {
  props: {
    form_numero_contrato: {
      type: Object,
      required: true,
      default: ()=>{}
    },
    objeto1: {
      type: Object,
      required: true,
      default: ()=>{}
    },
    generarDocumentos: {
      type: Function,
      required: true
    },
  },
  components: {
    DataTableDocumentos,
    VuePdfApp
  },
  data() {
    return {
      dialog_autorizar: false,
      dialog_visualizar_archivo:false,
      url_pdf: null,
      config: {
      errorWrapper: true,
      toolbar: {
        toolbarViewerLeft: {findbar: false}
      }
    },
    }
  },
  methods: {
    ...mapMutations('utils', ['setLoader']),
    async visualizarPdf({ id }) {
      this.setLoader(true)
      const id_oferta_persona = this.fasesInfo?.id_oferta;
      const response = await this.services.contratos.visualizarPdfPlantilla({
        id_plantilla: id,
        id_oferta_persona,
        buffer: true,
        numero_contrato: this.form_numero_contrato[`numero_${id}`]
      });
      if (response?.status === 200) {
        this.url_pdf = new Uint8Array(response.data.data).buffer;
        this.dialog_visualizar_archivo = true;
      }
      this.setLoader(false)
    },
  },
}
</script>