<template>
  <div>
    <v-dialog v-model="$attrs.value" :max-width="width" persistent style="background-color: transparent;">
      <v-card >
        <v-card-title style="word-break: normal;">
          <v-row class="pa-0">
            <v-col cols="12" class="mt-mb-0 mt-sm-0 mt-1 text-right">
              <v-toolbar dense flat>
                <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="$emit('input', !$attrs.value)">
                      <v-icon :size="$vuetify.breakpoint.xsOnly ? '25px' : '40px'">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-col cols="12" class="mt-0">
            <div class="text-center">
              <h1 class="font-weight-bold color-texto-principal" :class="$vuetify.breakpoint.xsOnly?'text-h5':''" style="color:#1C1E4D;">
                  {{ title }}
              </h1>
            </div>
          </v-col>
        </v-card-title>
        <v-card-text style="word-break: normal;">
            <slot name="body"/>
        </v-card-text>
        <v-card-actions>
            <slot name="actions"/>
        </v-card-actions>                
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default{
    props: {
        title: String,
        width:{
          type: String,
          default: '700'
        }
    },
}
</script>