<script>
import CardList from "@/views/solicitudes/components/CardList.vue";
import TablaTabs from "@/views/solicitudes/components/TablaTabs.vue";

export default {
  name: "Capacidades",
  components: { TablaTabs, CardList },
  props: {
    rol: {
      type: String,
      required: true,
      default: () => 'unidad'
    },
    idSoli: {
      type: Number,
      default: () => 7
    },
    isView: {
      type: Boolean,
    },

  },
  data() {
    return {
      paginacionOA: {
        pagina: 1,
        por_pagina: 3,
        total_registro: 0
      },
      paginacionCA: {
        pagina: 1,
        por_pagina: 3,
        total_registro: 0
      },
      listMenu: [
        {
          id: 1,
          name: 'Capacidades',
          icon: 'mdi-wrench-outline',
          tag: 'capacidades'
        },
        {
          id: 2,
          name: 'Otros aspectos',
          icon: 'mdi-clipboard-search-outline',
          tag: 'otros_aspectos'
        },

      ],
      headersCapacidades: [
        {
          text: 'Mostrar en oferta',
          value: 'activo',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'left',
          width: '180px'
        },
        {
          text: 'N°',
          value: 'correlativo',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'left',
          width: '120px'
        },
        {
          text: 'Descripción de actividad',
          value: 'nombre',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'left'
        },
      ],
      itemsCapacidades: [],
      headersOtrosAspectos: [
        {
          text: 'Mostrar en oferta',
          value: 'activo',
          class: 'black--text py-2 font-weight-bold text-subtitle-1 ',
          align: 'left',
          width: '180px'
        },
        {
          text: 'N°',
          value: 'correlativo',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'left',
          width: '120px'
        },
        {
          text: 'Nombre',
          value: 'nombre_aspecto',
          class: 'black--text py-2 font-weight-bold text-subtitle-1 otros',
          align: 'left'
        },
      ],
      itemsOtrosAspectos: [],
      id: 7,
    }
  },
  methods: {
    async getOtrosAspectos() {
      let params = {
        cantidad_por_pagina: this.paginacionOA.por_pagina,
        pagina: this.paginacionOA.pagina,
      }
      const { data } = await this.services.catalogos.getCatalogo(`otros_aspectos/${this.idSoli}`, params);
      if (Array.isArray(data.data)) {
        this.itemsOtrosAspectos = data.data.map((x, index) => {
          x.correlativo = index + 1;
          return x
        });
      } else {
        this.itemsOtrosAspectos = []
      }
      this.$nextTick(() => {
        this.paginacionOA.pagina = Number(data.meta.pagina)
        this.paginacionOA.por_pagina = Number(data.meta.cantidad_por_pagina)
        this.paginacionOA.total_registro = Number(data.meta.total)
      })
      for (let i = 0; i < this.itemsOtrosAspectos.length; i++) {
        this.$set(this.itemsOtrosAspectos[i], 'activo', false);
      }
      this.getOtrosAspectosAsignados()
    },
    async getCapacidades() {
      let params = {
        cantidad_por_pagina: this.paginacionCA.por_pagina,
        pagina: this.paginacionCA.pagina,
      }
      const { data } = await this.services.catalogos.getCatalogo(`capacidades/${this.idSoli}`, params);
      if (Array.isArray(data.data)) {
        this.itemsCapacidades = data.data.map((x, index) => {
          x.correlativo = index + 1;
          return x
        });
      } else {
        this.itemsCapacidades = []
      }
      this.$nextTick(() => {
        this.paginacionCA.pagina = Number(data.meta.pagina)
        this.paginacionCA.por_pagina = Number(data.meta.cantidad_por_pagina)
        this.paginacionCA.total_registro = Number(data.meta.total)
      })
      for (let i = 0; i < this.itemsCapacidades.length; i++) {
        this.$set(this.itemsCapacidades[i], 'activo', false);
      }
      this.getCapacidadesAsignadas()
    },
    async getOtrosAspectosAsignados() {
      const response = await this.services.catalogos.getOtrosAspectosAsig(this.$route.params.id)
      let idArreglo = response.data.ids
      for (let i = 0; i < this.itemsOtrosAspectos.length; i++) {
        const objeto = this.itemsOtrosAspectos[i];
        if (idArreglo.includes(objeto.id)) {
          this.$set(objeto, 'activo', true);
        }
      }
    },
    async getCapacidadesAsignadas() {
      const response = await this.services.catalogos.getCapacidadAsig(this.$route.params.id)
      let idArreglo = response.data.ids
      for (let i = 0; i < this.itemsCapacidades.length; i++) {
        const objeto = this.itemsCapacidades[i];
        if (idArreglo.includes(objeto.id)) {
          this.$set(objeto, 'activo', true);
        }
      }
    },
    async setPaginacionOtrosAspectos(pagina) {
      this.paginacionOA.pagina = pagina
      await this.getOtrosAspectos()
    },
    async setPaginacionCapacidades(pagina) {
      this.paginacionCA.pagina = pagina
      await this.getCapacidades()
    },
    async inputChange(event, item, tipo) {
      if (tipo === 'capacidades') {
        let body = {
          id_solicitud: Number(this.$route.params.id),
          id_catalogo: item.id
        }
        const response = await this.services.catalogos.postCatalogo('capacidades', body);
        if (response.status === 200 || response.status === 201) {
          this.getCapacidades();
        }
      } else if (tipo === 'otros_aspectos') {
        let body = {
          id_solicitud: Number(this.$route.params.id),
          id_catalogo: item.id
        }
        const response = await this.services.catalogos.postCatalogo('aspectos', body);
        if (response.status === 200 || response.status === 201) {
          this.getOtrosAspectos();
        }
      }
    },
    async init(){
      await Promise.race([
      this.getOtrosAspectos(),
      this.getCapacidades(),
    ])
    }
  },
  async created() {
    await this.init()
  },
}
</script>

<template>
  <!--  <div>-->
  <!--    <h1>Capacidades</h1>-->
  <!--    <v-btn @click="$emit('updatedData', {method: 'Capacidades', opts: {params: 1}})">Emitir data</v-btn>-->
  <!--  </div>-->

  <v-row>
    <v-col cols="12" md="3" class="text-center">
      <card-list :items="listMenu"></card-list>
    </v-col>
    <v-col cols="12" md="9" class="px-md-15">
      <v-row class="justify-end" v-if="rol === 'despacho' || rol === 'ufi'">
        <v-col cols="12" sm="5">
          <v-autocomplete label="Estado" outlined class="rounded-xl" dense>

          </v-autocomplete>

        </v-col>
      </v-row>
      <p class="text-h5 font-weight-bold primary--text" ref="capacidades">Capacidades </p>
      <v-row>
        <v-col class="px-sm-3 px-1">
        <v-card class="my-6 pa-sm-4" rounded="lg" elevation="5">
          <tabla-tabs :key-id="'id'" :data="itemsCapacidades" :headers="headersCapacidades"
            :por_pagina="paginacionCA.por_pagina" :page="paginacionCA.pagina"
            :total-registros="paginacionCA.total_registro" :slots-column="['activo']"
            @paginationChange="setPaginacionCapacidades" :rol="rol" nombre-pagina="pca">
            <template #[`activo`]="{ item }">
              <div class="d-flex align-center justify-center">
                <v-checkbox class="d-flex justify-center" color="primary" v-model="item.activo"
                  @change="inputChange($event, item, 'capacidades')" :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
                  :disabled="!['unidad', 'rrhh'].includes(rol) || isView"></v-checkbox>
              </div>
            </template>
          </tabla-tabs>
        </v-card>
      </v-col >
      </v-row>
      <p class="text-h5 font-weight-bold primary--text" ref="otros_aspectos">Otros aspectos</p>
      <v-row>
        <v-col class="px-sm-3 px-1">
          <v-card class="my-6 pa-sm-4" rounded="lg" elevation="5">
            <tabla-tabs :key-id="'id'" :data="itemsOtrosAspectos" :headers="headersOtrosAspectos"
              :por_pagina="paginacionOA.por_pagina" :page="paginacionOA.pagina"
              :total-registros="paginacionOA.total_registro" :slots-column="['activo']"
              @paginationChange="setPaginacionOtrosAspectos" :rol="rol" nombre-pagina="poa">
              <template #[`activo`]="{ item }">
                <div class="d-flex align-center justify-center">
                  <v-checkbox class="d-flex justify-center" color="primary" v-model="item.activo"
                    @change="inputChange($event, item, 'otros_aspectos')" :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
                    :disabled="!['unidad', 'rrhh'].includes(rol) || isView"></v-checkbox>
                </div>
              </template>
            </tabla-tabs>
          </v-card>
        </v-col >
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.otros {
  width: 900px;
}
</style>
