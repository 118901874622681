<template>


  <v-card>
  <v-card-text>
    <v-list nav dense>
      <v-list-item v-for="item in items" :key="item.id" style="cursor: pointer" @click="showScroll(item)">
        <v-row>
          <v-col cols="12" class="d-flex justify-center justify-md-start flex-row " style="gap: 15px">
            <v-icon color="primary">{{ item.icon }}</v-icon>
            <p class="ma-0 text-subtitle-1 primary--text">{{ item.name }}</p>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </v-card-text>
  </v-card>


</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  methods:{
    showScroll(item){
      // this.$emit('scroll', item)
      this.$nextTick(() => {
        this.$parent.$refs[item.tag].scrollIntoView({behavior: 'smooth'})
      })
    }
  }
}
</script>
