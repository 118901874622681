import Vue from 'vue'
import Vuex from 'vuex'
import utils from '../modules/utils'
import jwtDecode from "jwt-decode";
import contratos from "@/views/contratos/store/index";
import solicitud from "@/views/solicitudes/store";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        userInfo: {},
        mostrarMenu:false
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload
        },
        setUserInfo(state, payload) {
            // payload.roles.push('VIEW_LIST_SOLICITUDES', 'CREATE_SOLICITUsD')
            state.userInfo = payload
        },
    },
    actions:{
      setAuth({commit}, payload){
          const {token, refreshToken} =payload
          commit('setUserInfo', jwtDecode(token))
          commit('setToken', token)
          localStorage.setItem('token', token)
          localStorage.setItem('refresh_token', refreshToken)
      }
      
    },
    modules: {
        utils,
        contratos,
        solicitud
    }
})
