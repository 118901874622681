import Vue from "vue";
import VueRouter from "vue-router";
import rolesRouter from "../views/roles/rolesRouter";
import profilesRouter from "../views/profiles/profilesRouter";
import usersRouter from "../views/users/userRouter";
import contratosRouter from "../views/contratos/contratoRouter";
import pathsRouter from "../views/paths/pathRouter";
import routerSolicitudes from '../views/solicitudes/routers'
import routerNombramientos from '../views/nombramientos/router'
import "@/plugins/frontendRoles";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),
        children: [
            {
                path: "/",
                name: "dashboard",
                component: () =>
                    import(/* webpackChunkName: "profiles" */ "../views/dashboard"),
            },
            {
                path: "/choose-job",
                name: "selectPuesto",
                component: () =>
                    import(/* webpackChunkName: "profiles" */ "../views/auth/selectPuesto")
            },
            {
                path: "profile",
                name: "profile",
                component: () =>
                    import(/* webpackChunkName: "profiles" */ "../views/profile"),
            },
            ...rolesRouter,
            ...profilesRouter,
            ...usersRouter,
            ...pathsRouter,
            ...contratosRouter,
            ...routerSolicitudes,
            ...routerNombramientos,
            {
                path: "/security",
                name: "security",
                component: () => import(/* webpackChunkName: "qr" */ "../views/auth/security"),
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/auth/login"),
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
            import(
                /* webpackChunkName: "recuperarPassword" */ "../views/auth/recoverPassword"
                ),
    },
    {
        path: "/reset-password/:id",
        name: "reset-password",
        component: () =>
            import(/* webpackChunkName: "resetPassword" */ "../views/auth/resetPassword"),
    },
    {
        path: "/verify-mail/:token",
        name: "verifyMail",
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/auth/verifyMail"),
    },
    {
        path: "/forbidden",
        name: "Forbidden",
        component: () =>
            import(/* webpackChunkName: "forbidden" */ "../views/forbidden"),
    },
    {
        path: "/notFound",
        name: "NotFound",
        component: () =>
            import(/* webpackChunkName: "notFound" */ "../views/notFound.vue"),
    },
    {
        path: "/autenticacionqr",
        name: "2fa",
        component: () => import(/* webpackChunkName: "qr" */ "../views/auth/2fa"),
    },

];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
   const path = to.fullPath.substring(1);

    if (path.startsWith('https')) {
        window.location.replace(path)
    }
    // const externalRoute=[
    //     "contratacion",
    //     "bolsaEmpleo",
    //     "planillaNomina",
    //     "bolsaEmpleousuario"
    // ]
    // if (externalRoute.some((row) => row.toLowerCase() === to.nombre_uri.toLowerCase()))
    //    {
    //     window.location(to.uri)
    //     return true;
    //    }
    // else{
    //     next
    // }
    if (to.fullPath === "/") {
        const token = localStorage.getItem("token");
        if (!token) {
            next("/login");
            return;
        }
    }
   if (await Vue.prototype.canNext(to)) {
        next();
    } else {
        next("/notFound");
    }

    (Object.prototype.hasOwnProperty.call( to.meta, "rol" ))
        ? (await Vue.prototype.validRol( to.meta.rol ))
            ? next()
            :/*next("/forbidden")*/next() //ToDo Retornar /forbidden cuando ya se implemente los roles
        : next();
});

export default router;
