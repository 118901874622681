import {http_client} from "@/plugins/http_client";

const url = '/api/v1';

const getProcesoEspecifico = async(params={})=>http_client(`${url}/contratacion/establecimiento-proceso-contratacion`, params, 'get')

const updateProcesoEspecifico = async(id_proceso,params={})=>http_client(`${url}/contratacion/asignar-establecimiento-proceso/${id_proceso}`,params,'put')


export default {
    getProcesoEspecifico,
    updateProcesoEspecifico
}