import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '../assets/scss/index.scss'
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es',
    },
    theme: {
        themes: {
            //Se definen colores globales para el tema claro
            light : {
                blueMinsal:'#175EFB',
                blueGrayMinsal:'#6A83BE',
                blueDarkMinsal:'#2D52A8',
                bgMinsal:'#F4F7FD',
                bgWhite:"#FFFFFF",
                primary: '#1C1E4D',
                greenState: '#45C4B1',
                black: '#000000',
                bgTableFila: '#EDEDF1',
                bgIcon: '#1C1B1F',
                selectedBgPurple: "#697182",
            },
            dark:{
                bgMinsal:'#2f3032',
                blueMinsal:'#175EFB',
                blueGrayMinsal:'#6A83BE',
                blueDarkMinsal:'#2D52A8',
                primary: '#175EFB'
                // primary: {
                //     base: colors.purple.base,
                // }
            }
        }
    }
});
