<template>
  <v-tabs :vertical="$vuetify.breakpoint.smAndDown"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''" active-class="app-tabs-active" class="app-tabs"
      hide-slider background-color="transparent" color="1c1f4c" :grow="grow" :centered="!grow">
      <v-tab v-for="(tab, index) in tabs" :disabled="tab.disabled" :key="tab.title"
      @change="callInit(index)"
      class="app-tab text-capitalize font-weight-black"
      style="text-transform: none !important;" >

      <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon>{{ tab.icon }}</v-icon>
          {{ tab.title  }}
      </div>

      <div v-else style="padding: auto; !important;">
          <p style="font-size:80% !important;" class="pt-3">
          <v-icon class="ma-0">{{ tab.icon }}</v-icon>
          <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
          <span style="text-transform: none;">{{ tab.title }}</span>
          </p>
      </div>

      </v-tab>
      <v-tab-item v-for="(tab, index) in tabs" :key="tab.title" class="app-tab-body">
      <component v-model="solicitud" :idSoli="solicitud?.id_cargo_funcional" :sendSolicitud="sendSolicitud" :rol="rol" :isView="isView" v-if="!tab.disabled" :ref="tab.ref" :is="tab.component" class="py-8 px-3">
        <template #buttom >
          <slot name="buttomG" v-bind="$emit('input', solicitud)"/>
        </template>
      </component>
      </v-tab-item>
  </v-tabs>
</template>
<script>
export default{
  props:{
    tabs:{
      type: Array,
      default: ()=>[]
    },
    rol:{
      type: String,
      default: 'unidad'
    },
    sendSolicitud:{type: Function},
    isView:{
      type: Boolean,
      default: false
    },
    grow:{
      type: Boolean,
      default: true
    }
  },
  data:()=>({
    solicitud: null,
    selectedTab:null,
  }),
  methods:{
    async callInit(indice){
      const ref = this.tabs[indice].ref
      if(!!this.$refs[ref]){
        const componentInfo = this.$refs[ref][0]
        if(!!componentInfo.init){
          componentInfo.init()
        }
      }
    }
  }
}
</script>