<template>
  <div>
    <v-row class="mt-7 mx-4">
      <v-col>
        <v-pagination
          
          color="primary"
          v-model="pagina"
          @input="cambiarPagina"
          :length="totalPages"
          :total-visible="7"
          
        >
        </v-pagination>        
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: "AppPagination",
    props: {
      page: {
        type: Number,
        default: 1,
      },
      totalRegistros: {
        type: Number,
        default: 0,
      },
      perPage: {
        type: Number,
        default: 10,
      },
    },
    methods: {
      cambiarPagina(event) {
        this.$emit("input", event);
      },
      updatePerPage() {
        this.pagina = 1;
        this.$emit("change");
      },      
    },
    computed: {
      pagina: {
        get() {
          return this.page;
        },
        set(value) {
          this.$emit("update:page", value);
        },
      },
      porPagina: {
        get() {
          return this.perPage;
        },
        set(value) {
          this.$emit("update:perPage", value);
        },
      },
      totalPages() {
        return Math.ceil(this.totalRegistros / this.perPage);
      },
    },    
  }
</script>

<style lang="scss" scoped>
  .v-select {
    max-width: 55px !important;
  }
  :deep(.v-select__selection--comma) {
      margin: 7px 4px 7px 0;
      min-height: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #509ed7 !important;
  }
  
</style>
