<template>
  <div>
    <v-card class="my-6 pa-sm-4" rounded="lg" elevation="5">
      <TablaTabs
        :headers="headers"
        :data="actividades"
        :por_pagina="paginacion.por_pagina"
        :page="paginacion.pagina"
        :total-registros="paginacion.total_registro"
        :key-id="'id'"
        :slots-column="['activo']"
        :rol="rol"
        @paginationChange="setPaginacion"
      > 
        <template #[`activo`]="{item}">
            <v-checkbox
              color="primary"
              class="ml-10"
              v-model="item.activo"
              @change="inputChange($event, item)"
              :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
              :disabled="!['unidad', 'rrhh'].includes(rol) || isView "
            ></v-checkbox>
        </template>
      </TablaTabs>
    </v-card>
  </div>
</template>

<script>
import TablaTabs from '../../../components/TablaTabs.vue';

export default {
    name: "Actividades",
    props: {
      rol: {
        type: String,
        required: true,
        default: () => 'unidad'
      },
      idSoli: {
        type: Number,
        default: () => 7
      },
      isView:{
        type: Boolean,
      }
    },
    data() {
      return {
        actividades: [],
        headers: [
          {
              text: 'Mostrar en oferta',
              align: 'left',
              value: 'activo',
              width: this.$vuetify.breakpoint.smAndDown ? "5%" : "22%",
              class: 'black--text py-8 font-weight-bold text-subtitle-1'
          },
          {
              text: 'N°',
              value: 'correlativo',
              align: 'left',
              width: this.$vuetify.breakpoint.smAndDown ? "15%" : "5%",
              class: 'black--text py-8 font-weight-bold text-subtitle-1'
          },
          {
              text: 'Descripción de actividad',
              align: 'left',
              width: this.$vuetify.breakpoint.smAndDown ? "25%" : "200",
              value: 'descripcion',
              class: 'black--text py-8 font-weight-bold text-subtitle-1'
          },
          // {
          //   text: "Acciones", 
          //   value: "accion", 
          //   sortable: false, 
          //   width: "140", 
          //   class: 'black--text py-8 font-weight-bold text-subtitle-1'
          // },
        ],
        paginacion: {
          pagina: 1,
          por_pagina: 3,
          total_registro: 0
        },
        itemDeleted: null,
      };
    },
    components: { TablaTabs },
    methods: {
      async getActividades() {
        let params = {
          cantidad_por_pagina: this.paginacion.por_pagina,
          pagina: this.paginacion.pagina,
        }
        const { data } = await this.services.catalogos.getCatalogo(`actividades/${this.idSoli}`, params);
        if(Array.isArray(data.data)){
        this.actividades = data.data.map((x,index)=>{
          x.correlativo = index + 1;
          return x
        });
        }else{
          this.actividades = []
        }
        this.$nextTick(() => {
          this.paginacion.pagina = Number(data.meta.pagina)
          this.paginacion.por_pagina = Number(data.meta.cantidad_por_pagina)
          this.paginacion.total_registro = Number(data.meta.total)
        })
        for (let i = 0; i < this.actividades.length; i++) {
          this.$set(this.actividades[i], 'activo', false);
        }        
        this.getActividadesAsignadas()
      },
      async getActividadesAsignadas() {
        const response = await this.services.catalogos.getActividadAsig(this.$route.params.id)      
        let idArreglo = response.data
        for (let i = 0; i < this.actividades.length; i++) {
          const objeto = this.actividades[i];
          if (idArreglo.includes(objeto.id)) {
            this.$set(objeto, 'activo', true);
          }
        }                  
      },        
      async inputChange(event, item){
        let body = {
          id_solicitud: Number(this.$route.params.id),
          id_catalogo: item.id
        }
        const response = await this.services.catalogos.postCatalogo('actividades', body);

        if (response.status === 200 || response.status === 201) {
          this.getActividades()
        }
      },
      async setPaginacion(pagina) {
        this.paginacion.pagina = pagina
        await this.getActividades()
      },
      async init(){
        await this.getActividades()
      }
    },
    async created() {
      await Promise.race([
        this.init(),
      ])
    },
}
</script>

<style scoped lang="scss">

</style>
