import {http_client} from "@/plugins/http_client";

const url = '/api/v1/plantilla-correlativo'

const getPlantillaCorrelativo = async (params = {}) => await http_client(`${url}/`, params, 'get')
const putPlantillaCorrelativo = async (idPlantillas=null,params = {pagination:true,perPage:10}) => await http_client(`${url}/${idPlantillas}`, params, 'put')



export default {
    getPlantillaCorrelativo,
    putPlantillaCorrelativo
}