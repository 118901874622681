<template>
  <v-timeline>
    <v-row justify="center">
      <v-col cols="12" sm="8">
        <v-timeline-item v-for="(item, i) in comentarios" :key="i" color="#1d1e4d" small :class="getClass(i)">
          <template v-slot:opposite>
            <span :class="`headline font-weight-bold app-title-text-primary-color`" v-text="item.fecha_comentario"></span>
          </template>
          <div class="py-4">
            <h2 :class="`headline font-weight-bold mb-4 app-title-text-primary-color`">
              {{ `${item.nombre}-${item.unidad_organizativa.nombre}` }}
            </h2>
            <div>
              {{ item.comentario }}
            </div>
          </div>
        </v-timeline-item>
      </v-col>
    </v-row>

  </v-timeline>
</template>

<script>
export default {
  name: "LineaTiempoObservaciones",
  components: {},
  props: {
    comentarios: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  methods: {
    getClass(index){
      const numero =  index + 1;
      if(index%2 === 1){
        return 'text-right'
      }else{
        return ''
      }
    }
  },
  async created() { },
};
</script>
-
<style lang="scss" scoped>
.scroll-style {
  height: 790px;
  overflow: hidden;
  overflow-y: scroll;
}
.theme--light.v-timeline::before {
  background: #c1c1c1;
}
</style>
