import Vue from "vue";
import "@/plugins/frontendRoles";

export default [
    {
        path: "/nombramientos",
        name: "nombramiento",
        component: () => import(/* webpackChunkName: "solicitudesList" */ "./layout.vue"),
        children: [
            //! ============================Listado de nombramientos============================
            {
                path: "/",
                name: "lista-nombramientos",
                component: () => import(/* webpackChunkName: "solicitudesList" */ "./views/ListaNombramientos.vue"),
            },
        ]
    },
]
