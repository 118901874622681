<script>
import TablaTabs from "@/views/solicitudes/components/TablaTabs.vue";
import Dialogo from "../../../components/Dialogo.vue"
import {requiredIf, maxLength } from "vuelidate/lib/validators";

export default {
  name: "OpcionesExtras",
  components: {TablaTabs, Dialogo},
  props: {
    rol: {
      type: String,
      required: true,
      default: () => 'unidad'
    },
    idSoli: {
      type: Number,
      default: () => 7
    },
    isView:{
      type: Boolean,
    },
  },
  validations(){

    const notEmojis = (value) => {

      if(value!=null){
          const x = value.match(
            /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu
          );
          if (x != null) {
            return false;
          } else {
            return true;
          }
      }else{
        return true;
      }
    };

    return{
      formOpEx:{
        descripcion:{
          required: requiredIf(function () {
            return !this.exceptRRHH;
          }),
          notEmojis,
          maxLength: maxLength(500),
        }
      }
    }
  },
  data(){
    return {
      paginacionOE: {
        por_pagina: 3,
        pagina: 1,
        total_registro: 0,
      },
      id: 7,
      formOpEx: {
        descripcion: null,
      },
      headers: [
        {
          text: 'N°',
          value: 'corr_op',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'left',
          width: '15%'
        },
        {
          text: 'Descripción',
          value: 'descripcion',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'left',
          width: '65%'
        },
      ],
      items: [],
      dialogShow: false,
      opEx: null,
    }
  },
  methods: {
    async getOpcionesExtras() {
      let params = {
        cantidad_por_pagina: this.paginacionOE.por_pagina,
        pagina: this.paginacionOE.pagina,
      }
      const { data } = await this.services.catalogos.getOPE(this.$route.params.id, params);
     
      if(Array.isArray(data.data)){
        this.items = data.data.map((x,index)=>{
          x.corr_op = index + 1;
          return x
        });
      }else{
        this.items = []
      }
      this.$nextTick(() => {
        this.paginacionOE.pagina = Number(data.meta.pagina)
        this.paginacionOE.por_pagina = Number(data.meta.cantidad_por_pagina)
        this.paginacionOE.total_registro = Number(data.meta.total)
      })
    },
    async setPaginacionOpcionesExtras(pagina) {
        this.paginacionOE.pagina = pagina
        await this.getOpcionesExtras()
      },
    clearDescripcion(){
      this.$v.formOpEx.$reset();
      this.formOpEx.descripcion = null;
    },
    async agregarOpEx(){
      this.$v.formOpEx.$touch()


      if (!this.$v.formOpEx.$invalid) {
        let body = {
          id_solicitud: Number(this.$route.params.id),
          descripcion: this.formOpEx.descripcion,
        }
        const response = await this.services.catalogos.postOPE(body);
        this.clearDescripcion();
        this.getOpcionesExtras();
      }
    },
    deleteItem(item){
      this.opEx = item;
      this.dialogShow = true;
    },
    async eliminarItem(){
      const response = await this.services.catalogos.deleteOPE(this.opEx.id);
      if(response.status === 200){
        this.temporalAlert({
            show: true,
            message: response.data.message || "Se eliminó correctamente",
            type: "success",
          });
      }
      this.getOpcionesExtras();
      this.dialogShow = false;
    },
    closeDialog() {
      this.dialogShow = false;
    },
    async init(){
      await Promise.race([
        this.getOpcionesExtras(),
      ])
    }
  },
  computed: {
    exceptRRHH(){
      let isRRHH = this.rol !== 'unidad' ? true : false;
      return isRRHH
    },
    descripcionErrors() {
      const errors = []
      if (!this.$v.formOpEx.descripcion.$dirty) return errors
      !this.$v.formOpEx.descripcion.required && errors.push('Descripción es obligatorio')
      !this.$v.formOpEx.descripcion.notEmojis && errors.push('No debe incluir emojis')
      !this.$v.formOpEx.descripcion.maxLength && errors.push('Máximo 500 caracteres')
      return errors
    },
  },
  async created() {
    await this.init()
    if(this.rol === 'unidad'){
        let accion = {
          text: "Acciones",
          value: "accion",
          sortable: false,
          width: "20%",
          align: 'center',
          class: 'black--text py-8 font-weight-bold text-subtitle-1'
        }
        this.headers.push(accion);
      }
  },
}
</script>

<template>
<!--  <div>-->
<!--    <h1>Opciones extras</h1>-->
<!--    <v-btn @click="$emit('updatedData', {method: 'Opciones extras', opts: {params: 1}})">Emitir data</v-btn>-->
<!--  </div>-->

  <v-row>
    <v-col cols="12" md="8" v-if="!isView && rol === 'unidad'">
      <v-textarea v-model="formOpEx.descripcion" label="Descripción*" outlined  class="rounded-xl"
      @blur="$v.formOpEx.descripcion.$touch()"
      :readonly="isView"
      :error-messages="descripcionErrors"></v-textarea>
    </v-col>
    <v-col cols="12" md="1" class="d-flex align-end " v-if="!isView && rol === 'unidad' ">
      <v-btn color="primary white--text" class="rounded-xxl mb-8" :disabled="isView" @click="agregarOpEx" style="text-transform: none;">Agregar</v-btn>
    </v-col>
    <v-col cols="12" class="px-sm-3 px-1">
      <v-card class="my-6 pa-4" rounded="lg" elevation="5" >
        <tabla-tabs
            :key-id="'id'"
            :data="items"
            :headers="headers"
            :por_pagina="paginacionOE.por_pagina"
            :page="paginacionOE.pagina"
            :total-registros="paginacionOE.total_registro"
            :slots-column="['accion']" 
            :rol="rol"
            @paginationChange="setPaginacionOpcionesExtras"
        >
          <template #[`accion`]="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="isView"
                  class="ml-2"
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span> Eliminar </span>
            </v-tooltip>
          </template>
        </tabla-tabs>
      </v-card>
    </v-col>
    <Dialogo
      :visible ="dialogShow"
      @close="closeDialog"
      @guardar="eliminarItem"
      title="Eliminar opción"
      label="¿Desea eliminar la opción seleccionada?"
      label-btn="Eliminar"
    />
  </v-row>
</template>

<style scoped lang="scss">

</style>
