import { http_client } from "@/plugins/http_client";

const url = "/api/v1/categoria-descuento";

const urlParam = "api/v1/descuento-param-planilla";

const urlNombramiento = "api/v1/nombramiento";


const get = async (params = {}) => await http_client(url, params, "get");
const getDesc = async (id) => await http_client(`${urlNombramiento}/${id}/descuento`, {}, "get");
const getFiltro = async (params = {}) =>
  await http_client(urlParam, params, "get");
const post = async (id, body) =>
  await http_client(`${urlNombramiento}/${id}/descuento`, body, "post");
const remove = async (id, idDesc) =>
  await http_client(
    `${urlNombramiento}/${id}/descuento/${idDesc}`,
    {},
    "delete"
  );

export default {
  get,
  getDesc,
  getFiltro,
  post,
  remove,
};
