import {http_client} from "@/plugins/http_client";

const url = '/api/v1/fase-aprobacion'

const getFases = async (idProceso, params = {}) => await http_client(`${url}/${idProceso}/fases`, params, 'get')
const getFase = async (idFase) => await http_client(`${url}/${idFase}`, {}, 'get')
const postFases = async (params = { payload }) => await http_client(`${url}/`, params, 'post')
const putFases = async (idFase, params = { payload }) => await http_client(`${url}/${idFase}`, params, 'put')
const deleteFases = async (idFase) => await http_client(`${url}/${idFase}`, {}, 'delete')

export default {
    getFases,
    getFase,
    postFases,
    putFases,
    deleteFases,
}