<template>
    <div>
      <v-row justify-center>
        <v-col cols="12" sm="12" md="4">
            <v-row>
              <v-col>
                <h3 class="font-weight-black text-h6" style="color:#1c1f4c; font-weight: bold;">Solicitud de puesto</h3>
              </v-col>
              <v-col>
                <slot name="select"/>
              </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="8" >
        </v-col>
         <v-col cols="12" class="pb-0">
          <p class="font-weight-black">Fecha de solicitud: <span class="font-weight-regular"
            style="font-weight: 400 !important; color: #707070;">{{
            solicitudPuestoForm.fechaSolicitud | moment("DD/MM/YYYY")
          }}</span></p>
      </v-col>
      <v-col cols="12" class="pt-0">
        <p class="font-weight-black">Unidad solicitante: <span class="font-weight-regular"
            style="font-weight: 400 !important; color: #707070;">{{
            solicitudPuestoForm?.unidad || userInfo?.puesto?.unidad_organizativa?.nombre
          }}</span></p>
      </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
        <v-text-field
            v-model="solicitudPuestoForm.tituloOferta"
            class="rounded-xl"
            label="Título de la oferta*"
            outlined
            @blur="$v.solicitudPuestoForm.tituloOferta.$touch()"
            :error-messages="tituloOfertaErrors"
            :readonly="exceptRRHH"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
            v-model="solicitudPuestoForm.tipoContratacion"
            class="rounded-xl"
            :items="tipoContratacion"
            label="Tipo de contratación*"
            outlined
            item-text="nombre"
            item-value="id"
            no-data-text="No existe el tipo de contratación deseado"
            @blur="$v.solicitudPuestoForm.tipoContratacion.$touch()"
            :error-messages="tipoContratacionErrors"
            :readonly="exceptRRHH"
            @change="changeTipoContratacion"
        ></v-autocomplete>
      </v-col>
      </v-row>
      <v-row>
      <v-col cols="12" sm="8" md="8">
        <v-autocomplete
            v-model="solicitudPuestoForm.plazaSolicitar"
            class="rounded-xl"
            :items="plaza"
            label="Plaza a solicitar*"
            @keyup="searchPlaza(true)"
            no-filter
            :search-input.sync="search_plaza"
            :no-data-text="noDataTextPlaza"
            outlined
            item-text="nombre_cifrado"
            item-value="id"
            return-object
            @change="changePlaza"
            @blur="$v.solicitudPuestoForm.plazaSolicitar.$touch()"
            :error-messages="plazaSolicitarErrors"
            :readonly="exceptRRHH"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
            v-model="solicitudPuestoForm.categoria"
            class="rounded-xl"
            :items="categorias"
            label="Categoría*"
            outlined
            item-text="nombre"
            item-value="id"
            return-object
            no-data-text="No existe la categoría deseada"
            @change="changeCategoria"
            :readonly="exceptRRHH"
            ></v-autocomplete>
            <!-- @blur="$v.solicitudPuestoForm.categoria.$touch()"
            :error-messages="categoriaErrors" -->
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
            v-model="solicitudPuestoForm.cargoSolicitar"
            no-filter
            :search-input.sync="search_cargo"
            @keyup="searchCargos(true)"
            :no-data-text="noDataTextCargos"
            class="rounded-xl"
            :items="cargos"
            label="Cargo a solicitar*"
            outlined
            item-text="nombre"
            item-value="id"
            @blur="$v.solicitudPuestoForm.cargoSolicitar.$touch()"
            :error-messages="cargoSolicitarErrors"
            :readonly="exceptRRHH"
            @change="changeCargoSolicitar"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6" md="4" class="d-flex align-center justify-start">
            <p class="font-weight-black">Vacantes disponibles: <span class="font-weight-regular"
                                                                      style="font-weight: 400 !important; color: #707070;">{{
                solicitudPuestoForm.vacantesDisponible
              }}</span></p>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
                v-model="solicitudPuestoForm.vacantesSolicitar"
                class="rounded-xl"
                label="Número de vacantes a solicitar*"
                outlined
                @keypress="restrictInteger"
                @blur="$v.solicitudPuestoForm.vacantesSolicitar.$touch()"
                :error-messages="vacantesSolicitarErrors"
                :readonly="exceptRRHH"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-textarea
                v-model="solicitudPuestoForm.motivo"
                outlined
                label="Motivo*"
                class="rounded-xl"
                @blur="$v.solicitudPuestoForm.motivo.$touch()"
                :error-messages="motivoErrors"
                :readonly="exceptRRHH"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6" md="4" class="d-flex align-center justify-start">
            <p class="font-weight-black">Salario base según plaza: <span class="font-weight-regular"
              style="font-weight: 400 !important; color: #707070;" v-if="salarioMinimo != 0.00 && salarioMaximo != 0.00"> {{
                $currency.format(salarioMinimo)
              }} MIN - {{ salarioMax }}  </span></p>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-currency-field
                v-model="solicitudPuestoForm.salarioPropuesto"
                class="rounded-xl"
                label="Salario propuesto*"
                outlined
                prefix="$"
                maxlength="20"
                @blur="$v.solicitudPuestoForm.salarioPropuesto.$touch()"
                :error-messages="salarioPropuestoErrors"
                :readonly="exceptRRHH"
            ></v-currency-field>

          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
                v-model="solicitudPuestoForm.tipoHorario"
                class="rounded-xl"
                :items="tiposHorarios"
                label="Tipo horario*"
                outlined
                item-text="nombre"
                item-value="id"
                no-data-text="No existe el tipo de horario deseado"
                @blur="$v.solicitudPuestoForm.tipoHorario.$touch()"
                :error-messages="tipoHorarioErrors"
                :readonly="exceptRRHH"
                @change="changeTipoHorario"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
                v-model="solicitudPuestoForm.horario"
                class="rounded-xl"
                :items="horarios"
                label="Horario*"
                outlined
                item-text="nombre"
                item-value="id"
                no-data-text="No existe el horario deseado"
                @blur="$v.solicitudPuestoForm.horario.$touch()"
                :error-messages="horarioErrors"
                :readonly="exceptRRHH"
            >
              <template v-slot:selection="data">
                <!-- // data.item.nombre + ' | ' + -->
                <span>{{
                     (moment(data.item.hora_entrada, 'HH:mm:ss').format('hh:mm A')) + ' - ' + (moment(data.item.hora_salida, 'HH:mm:ss').format('hh:mm A'))
                  }}</span>
              </template>
              <template v-slot:item="data">
                <!-- // data.item.nombre + ' | ' +  -->
                <span>{{
                    (moment(data.item.hora_entrada, 'HH:mm:ss').format('hh:mm A')) + ' - ' + (moment(data.item.hora_salida, 'HH:mm:ss').format('hh:mm A'))
                  }}</span>
              </template>
            </v-autocomplete> 
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <p class="font-weight-black">Discapacidad</p>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div style="font-weight: 400 !important;" class="text-start text-subtitle-1 pt-2">¿Aplica para personas con
              discapacidad?
            </div>
            <v-radio-group
                v-model="solicitudPuestoForm.discapacidad"
                row
                :readonly="exceptRRHH"
            >
              <v-radio
                  label="Si"
                  :value="true"
              ></v-radio>
              <v-radio
                  label="No"
                  :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
                v-model="solicitudPuestoForm.sexo"
                class="rounded-xl"
                :items="sexo"
                label="Sexo*"
                outlined
                item-text="nombre"
                item-value="id"
                no-data-text="No existe el sexo deseado"
                @blur="$v.solicitudPuestoForm.sexo.$touch()"
                :error-messages="sexoErrors"
                :readonly="exceptRRHH"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <slot name="buttom"/>
      <slot name="dialogs"/>
    </v-row>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { maxValue, minValue, requiredIf, maxLength, numeric } from "vuelidate/lib/validators";
  export default {
    name: "Solicitud",
    props: {
      rol: {
        type: String,
        required: true,
      },
      idSoli: {
        type: Number,
        default: () => 7
      },
      sendSolicitud:{ type: Function },
      dataSolicitud: { type: Object },
      isView:{ type: Boolean }
    },
    validations() {
      const notEmojis = (value) => {
        const x = value?.match(
          /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu
        );
        if (x != null) {
          return false;
        } else {
          return true;
        }
      };
      return {
        solicitudPuestoForm: {
          tituloOferta: {
            required: requiredIf(function () {
              return !this.exceptRRHH;
            }),
            notEmojis,
            maxLength: maxLength(80),
          },
          plazaSolicitar: {
            required: requiredIf(function () {
              return !this.exceptRRHH;
            })
          },
          cargoSolicitar: {
            required: requiredIf(function () {
              return !this.exceptRRHH;
            })
          },
          tipoContratacion: {
            required: requiredIf(function () {
              return !this.exceptRRHH;
            })
          },
          // categoria: {
          //   required: requiredIf(function () {
          //     return !this.exceptRRHH;
          //   })
          // },
          vacantesSolicitar: {
            required: requiredIf(function () {
              return !this.exceptRRHH;
            }),
            numeric,
            minValue : minValue(1)
            // maxValue: maxValue(this.solicitudPuestoForm.vacantesDisponible)
          },
          salarioPropuesto: {
            maxValue: maxValue(this.validSalarioMaximo),
            minValue: minValue(this.salarioMinimo),
            required: requiredIf(function () {
              return !this.exceptRRHH;
            }),
          },
          motivo: {
            required: requiredIf(function () {
              return !this.exceptRRHH;
            }),
            notEmojis,
            maxLength: maxLength(500),
          },
          tipoHorario: {
            required: requiredIf(function () {
              return !this.exceptRRHH;
            })
          },
          horario: {
            required: requiredIf(function () {
              return !this.exceptRRHH;
            })
          },
          sexo: {
            required: requiredIf(function () {
              return !this.exceptRRHH;
            })
          },
        }
      }
    },
    data() {
      return {
        search_cargo: "",
        search_plaza:"",
        id_establecimiento: null,
        solicitudPuestoForm: {
          fechaSolicitud: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          unidad: null,
          tituloOferta: null,
          plazaSolicitar: null,
          cargoSolicitar: null,
          tipoContratacion: null,
          categoria: null,
          vacantesDisponible: '',
          vacantesSolicitar: null,
          motivo: null,
          salarioPropuesto: null,
          tipoHorario: null,
          horario: null,
          discapacidad: null,
          sexo: null,
        },
        salarioMaximo: 0,
        salarioMinimo: 0,
        categorias: [],
        plaza: [],
        cargos: [],
        tipoContratacion: [],
        dirUniArea: [],
        tiposHorarios: [],
        horarios: [],
        sexo: [],
        isSearchingCargo:false,
        isSearchingPlaza: false,
        cargoSelected: null,
        changedCargo: false,
        isCreated: false,
      }
    },
    methods: {
    timeout(ms = 1000){
      if(this.searchTimeout){
          clearInterval(this.searchTimeout)
      }
      return  new Promise((resolve)=>{
          this.searchTimeout = setTimeout(resolve, ms)
      })
    },
    changeCategoria(event) {
      if (event) {
        this.salarioMaximo = event?.salario_maximo;
        this.salarioMinimo = event?.salario_minimo;
        this.solicitudPuestoForm.salarioPropuesto = event?.salario_minimo
      }else {
        const plazaEncontrada = this.plaza.find(plaza => plaza.id === this.solicitudPuestoForm?.plazaSolicitar?.id);
        this.salarioMinimo = plazaEncontrada.salario_minimo;
        this.salarioMaximo = plazaEncontrada.salario_maximo;
      }
    },
    async changeTipoHorario(event) {
      this.horarios = []
      if (event) {
        this.getHorario(event);
      } else {
        this.solicitudPuestoForm.horario = null;
      }
    },
    async changeTipoContratacion(event) {
      this.plaza = []
      this.solicitudPuestoForm.plazaSolicitar = null
      this.solicitudPuestoForm.categoria = null
      if (event) {
        this.getPlaza(event);
      } else {
        this.solicitudPuestoForm.plazaSolicitar = null;
      }
    },
    async changePlaza(event){
      this.categorias = []
      this.solicitudPuestoForm.categoria = null
      if (event) {
        this.getCategoria(event.id_plaza_establecimiento);
        this.getPlazasDisponibles(event.id_plaza_establecimiento);
      } else {
        this.solicitudPuestoForm.categoria = null;
        this.solicitudPuestoForm.vacantesDisponible = 0;
      }
    },
    async changeCargoSolicitar(event) {
      if (this.cargoSelected !== event) {
        this.changedCargo = true;
      } else {
        this.changedCargo = false;
      }
    },
    async getSexo() {
      const response = await this.services.catalogos.getCatalogo('sexo')
      this.sexo = response.data
    },
    async getTipoHorario() {
      const response = await this.services.catalogos.getCatalogo('tipo_horario')
      this.tiposHorarios = response.data
    },
    async getHorario(tipoHorario) {
      let params = {
        tipo_horario: tipoHorario
      }
      if(this.id_establecimiento) params.id_establecimiento = this.id_establecimiento
      const response = await this.services.catalogos.getCatalogo('jornada', params)
      this.horarios = response.data
    },
    async getTipoContratacion() {
      const response = await this.services.catalogos.getCatalogo('sistema_pago')
      this.tipoContratacion = response.data
    },
    async getPlaza(sistemaPago) { 
      if(!!sistemaPago){
        let params = {
          id_sistema_pago: sistemaPago,
          pagination:true, per_page: 5,
          id_search: this.solicitudPuestoForm.plazaSolicitar
        }
        if(this.id_establecimiento) params.id_establecimiento = this.id_establecimiento
        const response = await this.services.catalogos.getCatalogo('plaza', params)
        this.plaza = response.data.data
      }

    },
    async getCategoria(plazaEst) {
      const response = await this.services.catalogos.getCatalogoCat(plazaEst)    
      this.categorias = response.data
    },
    async getPlazasDisponibles(plazaEst) {
      const response = await this.services.catalogos.getCatalogo('total_plazas_disponibles', {
        id_plaza_establecimiento: plazaEst
      })     
      this.solicitudPuestoForm.vacantesDisponible = response.data.total_disponible
    },      
    async getDirUniArea() {
      const { data } = await this.services.catalogos.getUnidad(this.$route.params.id)
      this.dirUniArea = data
    },
    async getCargos() {
      let params = {
        pagination:true,
        per_page: 5,
        id_search: this.solicitudPuestoForm.cargoSolicitar 
      }
        
      if(this.id_establecimiento) params.id_establecimiento = this.id_establecimiento

      const response =  await this.services.catalogos.getCatalogo('cargo', params)
      this.cargos = response.data.data
    },
    async searchCargos(typing = false){
      if(this.search_cargo?.length>=3){
        if(typing) {
          this.isSearchingCargo = typing
          await this.timeout()
        }
        const response = await this.services.catalogos.getCatalogo('cargo', { busqueda: this.search_cargo })
        this.isSearchingCargo = !typing
        
        this.cargos = response.status == 200 ? response.data : []
      }
    },
    async searchPlaza(typing = false){
      if(this.search_plaza?.length>=3){
        if(typing) {
          this.isSearchingPlaza = typing
          await this.timeout()
        }
        if(!!this.solicitudPuestoForm.tipoContratacion){
          const response = await this.services.catalogos.getCatalogo('plaza', {id_sistema_pago: this.solicitudPuestoForm.tipoContratacion,  busqueda: this.search_plaza })
          this.isSearchingPlaza = !typing
          
          this.plaza = response.status == 200 ? response.data : []
        }
      }
    },
    async emitirData() {
      this.$v.solicitudPuestoForm.$touch()
      if (!this.$v.solicitudPuestoForm.$invalid) {
        const removeObjectEmptyValues = (obj) => {
          // Obtenemos las llaves que tienen valores nulos o una cadena vacía
          const keysToRemove = Object.keys(obj).filter(key => obj[key] === null || obj[key] === '');
          // Eliminamos las llaves del objeto
          keysToRemove.forEach(key => {//* no se tiene que borrar si va null el id_categoria_plaza
            if(key !== 'id_categoria_plaza') delete obj[key]
          });
          return obj;
        };
        let oferta = {
          titulo_oferta: this.solicitudPuestoForm.tituloOferta,
          numero_vacantes: Number(this.solicitudPuestoForm.vacantesSolicitar),
          motivo_numero_vacante: this.solicitudPuestoForm.motivo,
          salario_propuesto: this.solicitudPuestoForm.salarioPropuesto,
          discapacidad: this.solicitudPuestoForm.discapacidad,
          id_cargo_funcional: this.solicitudPuestoForm.cargoSolicitar,
          id_jornada: this.solicitudPuestoForm.horario,
          id_sexo: this.solicitudPuestoForm.sexo,
          id_detalle_plaza: typeof this.solicitudPuestoForm.plazaSolicitar === 'object' ? this.solicitudPuestoForm.plazaSolicitar.id : this.solicitudPuestoForm.plazaSolicitar,
        };
        if(this.solicitudPuestoForm.categoria){
          oferta.id_categoria_plaza  = typeof this.solicitudPuestoForm.categoria === 'object' ? this.solicitudPuestoForm.categoria.id : this.solicitudPuestoForm.categoria
        }else{
          oferta.id_categoria_plaza = null
        }
        if (this.isCreated) oferta.id_solicitud = Number(this.$route.params.id)
        const cleanedOferta = removeObjectEmptyValues(oferta);
        this.$props.sendSolicitud(cleanedOferta);
      }
    },
    async getSolicitud() {
      const id = this.$route.params.id ?? null
      if(!id)return
      const response = await this.services.catalogos.getSolicitud(id)
      if(response.status === 200){
        this.$emit('input',response.data.solicitud);
        this.solicitud_fase_solicitud = response.data.solicitud.solicitud_fase_solicitud
        this.solicitudPuestoForm.fechaSolicitud = response.data.solicitud.fecha_solicitud
        this.solicitudPuestoForm.unidad = response.data.solicitud.unidad.nombre
        this.solicitudPuestoForm.tituloOferta = response.data.solicitud.titulo_oferta
        this.solicitudPuestoForm.tipoContratacion = response.data.idSistemaPago[0]
        this.solicitudPuestoForm.plazaSolicitar = response.data.solicitud.id_detalle_plaza
        await this.getPlaza(this.solicitudPuestoForm.tipoContratacion)
        const plazaEncontrada = this.plaza.find(plaza => plaza.id === response.data.solicitud.id_detalle_plaza);
        this.solicitudPuestoForm.cargoSolicitar = response.data.solicitud.id_cargo_funcional
        this.cargoSelected = response.data.solicitud.id_cargo_funcional
        await this.getCategoria(plazaEncontrada.id_plaza_establecimiento);
        const categoriaEncontrada = this.categorias.find((item)=>item.id === response.data.solicitud?.id_categoria_plaza)
        if(categoriaEncontrada){
          this.salarioMinimo = categoriaEncontrada.salario_minimo;
          this.salarioMaximo = categoriaEncontrada.salario_maximo;
        }else{
          this.salarioMinimo = plazaEncontrada.salario_minimo;
          this.salarioMaximo = plazaEncontrada.salario_maximo;
        }
        await this.getPlazasDisponibles(plazaEncontrada.id_plaza_establecimiento);
        this.solicitudPuestoForm.categoria = response.data.solicitud.id_categoria_plaza
        this.solicitudPuestoForm.salarioPropuesto = response.data.solicitud.salario_propuesto
        this.solicitudPuestoForm.vacantesSolicitar = response.data.solicitud.numero_vacantes
        this.solicitudPuestoForm.motivo = response.data.solicitud.motivo_numero_vacante
        this.solicitudPuestoForm.tipoHorario = response.data.solicitud.jornada.id_tipo_horario
        await this.getHorario(this.solicitudPuestoForm.tipoHorario)
        this.solicitudPuestoForm.horario = response.data.solicitud.id_jornada
        this.solicitudPuestoForm.discapacidad = response.data.solicitud.discapacidad
        this.solicitudPuestoForm.sexo = response.data.solicitud.id_sexo
        this.fase_avanzar=response.data.solicitud.fase_avanzar;
        this.idEstado=response.data.solicitud.id_estado_fase_solicitud;
        this.IdSolicitud=this.$route.params.id;
      }
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    exceptRRHH() {
      let normalValid = this.$props.isView || !['solicitud-rrhh','solicitud-created'].includes(this.$route.name)
      let exeptionValid = this.$route.meta.isSolventar
      return this.$route.meta.hasOwnProperty('isSolventar') ? !exeptionValid : normalValid
    },
    noDataTextCargos(){
      if(this.isSearchingCargo){
        return "...buscando"
      }else if(this.cargos.length  === 0 &&  this.search_cargo?.length>=3 && this.isSearchingCargo === false){
        return 'No hay datos'
      }else{
        return 'Digite un nombre para buscar'
      }
    },
    noDataTextPlaza(){
      if(!this.solicitudPuestoForm.tipoContratacion){
        return "Seleccione antes un tipo de contratación"
      }
      if(this.isSearchingPlaza){
        return "...buscando"
      }else if(this.plaza.length  === 0 &&  (this.search_plaza?.length>=3 && this.isSearchingPlaza === false)){
        return 'No hay datos'
      }else{
        return 'Digite un nombre para buscar'
      }
    },
    tituloOfertaErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.tituloOferta.$dirty) return errors
      !this.$v.solicitudPuestoForm.tituloOferta.required && errors.push('Título de la oferta es obligatorio')
      !this.$v.solicitudPuestoForm.tituloOferta.notEmojis && errors.push('No debe incluir emojis')
      !this.$v.solicitudPuestoForm.tituloOferta.maxLength && errors.push('Máximo 80 caracteres')

      return errors
    },
    plazaSolicitarErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.plazaSolicitar.$dirty) return errors
      !this.$v.solicitudPuestoForm.plazaSolicitar.required && errors.push('Plaza a solicitar es obligatorio')
      return errors
    },
    cargoSolicitarErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.cargoSolicitar.$dirty) return errors
      !this.$v.solicitudPuestoForm.cargoSolicitar.required && errors.push('Cargo a solicitar es obligatorio')
      return errors
    },
    tipoContratacionErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.tipoContratacion.$dirty) return errors
      !this.$v.solicitudPuestoForm.tipoContratacion.required && errors.push('Tipo de contratación es obligatorio')
      return errors
    },
    categoriaErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.categoria.$dirty) return errors
      !this.$v.solicitudPuestoForm.categoria.required && errors.push('Categoría es obligatorio')
      return errors
    },
    vacantesSolicitarErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.vacantesSolicitar.$dirty) return errors
      !this.$v.solicitudPuestoForm.vacantesSolicitar.required && errors.push('Vacantes a solicitar es obligatorio')
      !this.$v.solicitudPuestoForm.vacantesSolicitar.numeric && errors.push('Debe ser un valor entero positivo')
      !this.$v.solicitudPuestoForm.vacantesSolicitar.minValue && errors.push('Debe ser un valor mayor a 0')
      // !this.$v.solicitudPuestoForm.vacantesSolicitar.maxValue &&
      // errors.push(`El valor tiene que ser menor que ${this.solicitudPuestoForm.vacantesDisponible}`)

      return errors
    },
    salarioPropuestoErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.salarioPropuesto.$dirty) return errors
      if (!(this.salarioMaximo <= 0 || this.salarioMaximo === null) && !this.$v.solicitudPuestoForm.salarioPropuesto.maxValue) {
      errors.push(`El salario tiene que ser menor que ${this.$currency.format(this.salarioMaximo)}`);}
      !this.$v.solicitudPuestoForm.salarioPropuesto.minValue && errors.push(`El salario tiene que ser mayor que ${this.$currency.format(this.salarioMinimo)}`)
      !this.$v.solicitudPuestoForm.salarioPropuesto.required && errors.push('El salario es obligatorio')
      return errors
    },
    motivoErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.motivo.$dirty) return errors
      !this.$v.solicitudPuestoForm.motivo.required && errors.push('Motivo es obligatorio')
      !this.$v.solicitudPuestoForm.motivo.notEmojis && errors.push('No debe incluir emojis')
      !this.$v.solicitudPuestoForm.motivo.maxLength && errors.push('Máximo 500 caracteres')
      return errors
    },
    tipoHorarioErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.tipoHorario.$dirty) return errors
      !this.$v.solicitudPuestoForm.tipoHorario.required && errors.push('Tipo de horario es obligatorio')
      return errors
    },
    horarioErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.horario.$dirty) return errors
      !this.$v.solicitudPuestoForm.horario.required && errors.push('Horario es obligatorio')
      return errors
    },
    sexoErrors() {
      const errors = []
      if (!this.$v.solicitudPuestoForm.sexo.$dirty) return errors
      !this.$v.solicitudPuestoForm.sexo.required && errors.push('Sexo es obligatorio')
      return errors
    },
    calcularSalarioMaximo() {
      if ((this.solicitudPuestoForm.plazaSolicitar !== null && typeof this.solicitudPuestoForm.plazaSolicitar === 'object')) {
        return this.solicitudPuestoForm.plazaSolicitar ? this.solicitudPuestoForm.plazaSolicitar.salario_maximo : 0.00;
      } else {
        return 0.00
      }
    },
    calcularSalarioMinimo() {
      if ((this.solicitudPuestoForm.plazaSolicitar !== null && typeof this.solicitudPuestoForm.plazaSolicitar === 'object')) {
        return this.solicitudPuestoForm.plazaSolicitar ? this.solicitudPuestoForm.plazaSolicitar.salario_minimo : 0.00;
      } else {
        return 0.00
      }
    },
    salarioMax(){
      return !this.salarioMaximo ? 'MAX No definido' : `${this.$currency.format(this.salarioMaximo) } MAX`
    },
    validSalarioMaximo(){
      return this.salarioMaximo ? this.salarioMaximo : Number(this.solicitudPuestoForm.salarioPropuesto) + 0.01
    }
  },
  watch: {

    calcularSalarioMaximo(nuevoValor) {
      this.salarioMaximo = nuevoValor;
    },
    calcularSalarioMinimo(nuevoValor) {
      this.salarioMinimo = nuevoValor;
    },
    async search_cargo(){
      if(this.search_cargo !== null){
        if(this.search_cargo.length <= 3 ){
          this.cargos= []
          if(this.search_plaza.length === 0){
            await this.getCargos()
          }
        }
      }
    },
    async search_plaza(){
      if(this.search_plaza !== null){
        if(this.search_plaza.length <= 3 ){
          this.plaza= []
          if(this.search_plaza.length === 0){
            await this.getPlaza(this.solicitudPuestoForm.tipoContratacion)
          }
        }
      }
    }

  },
  async created() {
    this.id_establecimiento = this.$route.params.establecimiento
    this.isCreated = this.$route.params.hasOwnProperty('id')
    if(this.$route.params.id){
      await this.getSolicitud()
    }
    await Promise.all([
    this.getTipoHorario(),
    this.getSexo(),
    this.getTipoContratacion(),
    this.getDirUniArea(),
    this.getCargos()
    ])
  }
}
</script>