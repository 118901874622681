import {http_client} from "@/plugins/http_client";

const url = '/api/v1/solicitud/catalogos'
const getCatalogo = async (tipo, params = {}) => await http_client(`${url}/${tipo}`, params, 'get')
const getCatalogoCat = async (id, params = {}) => await http_client(`${url}/categoria/${id}`, params, 'get')
const putCatalogo = async (tipo, id, body) => await http_client(`${url}/${tipo}/${id}`, body, 'put')
const postCatalogo = async (tipo, body) => await http_client(`${url}/${tipo}`, body, 'post')

const url2 = '/api/v1/solicitud/catalogo'
const getCatalogo2 = async (tipo, params = {}) => await http_client(`${url2}/${tipo}`, params, 'get')
const putCatalogo2 = async (tipo, id, body) => await http_client(`${url2}/${tipo}/${id}`, body, 'put')

const urlSoli = '/api/v1/solicitud'
const postSolicitud = async (body) => await http_client(`${urlSoli}/created `, body, 'post')
const getSolicitud = async (idSoli,body) => await http_client(`${urlSoli}/${idSoli}`,body, 'get')
const updateSolicitud = async (body) => await http_client(`${urlSoli}/update `, body, 'put')
const getOPE = async (id, params = {}) => await http_client(`${urlSoli}/opciones_extras/${id}`, params, 'get')
const postOPE = async (body) => await http_client(`${urlSoli}/opciones_extras`, body, 'post')
const deleteOPE = async (id) => await http_client(`${urlSoli}/opciones_extras/${id}`, {}, 'delete')
const postEvaluacion = async (body) => await http_client(`${urlSoli}/evaluacion`, body, 'post')
const putEvaluacion = async (id, body) => await http_client(`${urlSoli}/evaluacionrrhh/${id}`, body, 'put')
const getEvaluaciones = async (id, params = {}) => await http_client(`${urlSoli}/evaluacion/${id}`, params, 'get')
const getEvaluacion = async (id, id_evaluacion, params = {}) => await http_client(`${urlSoli}/evaluacion/${id}/${id_evaluacion}`, params, 'get')
const deleteEvaluacion = async (id, body = {}) => await http_client(`${urlSoli}/evaluacion/${id}`, body, 'delete')
const getActividadAsig = async (idSoli) => await http_client(`${urlSoli}/actividad-asignada/${idSoli}`, {}, 'get')
const getConocimientoAsig = async (idSoli) => await http_client(`${urlSoli}/conocimiento-asignado/${idSoli}`, {}, 'get')
const getExperienciaAsig = async (idSoli) => await http_client(`${urlSoli}/experiencia-asignada/${idSoli}`, {}, 'get')
const getFormacionAsig = async (idSoli) => await http_client(`${urlSoli}/formacion-asignada/${idSoli}`, {}, 'get')
const getCapacidadAsig = async (idSoli) => await http_client(`${urlSoli}/capacidad-asignada/${idSoli}`, {}, 'get')
const getOtrosAspectosAsig = async (idSoli) => await http_client(`${urlSoli}/otros-aspecto-asignado/${idSoli}`, {}, 'get')
const getEvaluacionLey = async (idSoli) => await http_client(`${urlSoli}/evaluacion-ley/${idSoli}`, {}, 'get')
const putEvaluarSolicitud = async (idSoli, params) => await http_client(`${urlSoli}/evaluar/${idSoli}`, params, 'put')

const urlCatalogo = '/api/v1/catalogos'
const getUnidad = async (pagination=false) => await http_client(`${urlCatalogo}/unidad`, {pagination}, 'get')
const getCategorias = async () => await http_client(`${urlCatalogo}/categoria`, {}, 'get')
const getNivelExperiencia = async () => await http_client(`${urlCatalogo}/nivel-experiencia`, {}, 'get')

const getHorario = async (jornadaId) => await http_client(`/api/v1/solicitud/catalogos/jornada/${jornadaId}`, {}, 'get')

const urlPlaza = '/api/v1/catalogos/plaza'

const getPlaza = async (params = {}) => await http_client(`${urlPlaza}`, params, 'get')

export default {
  getCatalogo,
  getCatalogo2,
  putCatalogo,
  putCatalogo2,
  getOPE,
  postOPE,
  deleteOPE,
  postSolicitud,
  postEvaluacion,
  getEvaluaciones,
  getEvaluacion,
  putEvaluacion,
  deleteEvaluacion,
  getSolicitud,
  updateSolicitud,
  getUnidad,
  getCategorias,
  getPlaza,
  postCatalogo,
  getCatalogoCat,
  getActividadAsig,
  getConocimientoAsig,
  getExperienciaAsig,
  getFormacionAsig,
  getCapacidadAsig,
  getOtrosAspectosAsig,
  getEvaluacionLey,
  getNivelExperiencia,
  putEvaluarSolicitud,
  getHorario
}
/*  */