<template>
  <section>
    <v-btn
      @click="generarPDF"
      :icon="btnAction.btnIcon"
      :outlined="btnAction.outlined"
      :color="btnAction.color"
      :class="btnAction.class"
      :loading= "cargandoPDF"
      :dark="btnAction.dark"
      class="btn-text"
    >
      <v-icon class="mr-2">{{ btnAction.icon }}</v-icon>
      {{ btnAction.text }}
    </v-btn>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Plantilla.pdf"
      :pdf-quality="5"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="1500px"

      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      @hasDownloaded="hasDownloaded()"
      
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div v-html="contentHTML"></div>
      </section>
    </vue-html2pdf>
  </section>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
    VueHtml2pdf
  },

  props: {
    contentHTML: {
      type: String,
      default: ''
    },
    btnAction: {
      type: Object,
      default: () => {
        return {
          text: 'Generar PDF',
          icon: 'mdi-file-document-arrow-right',
          color: 'primary',
          class: ['rounded-pill'],
          btnIcon: false,
          outlined: false,
          dark: false
        }
      }
    }
  },

  data () {
    return {
      cargandoPDF: false
    }
  },

  methods: {
    onProgress(progress) {
      // this.cargandoPDF = true
      if (progress.progress === 100) {
        // console.log('onProgress', progress === 100)
        // this.cargandoPDF = false
      }
    },
    closePdfModal() {
      // Accede al componente Vue-html2pdf a través de la referencia
      const pdfComponent = this.$refs.html2Pdf;

      // Llama a un método para cerrar el modal (esto depende de la implementación del componente)
      // Esto es un ejemplo ficticio, debes verificar la documentación del componente para los métodos disponibles
      if (pdfComponent.closeModal) {
        pdfComponent.closeModal();
        this.cargandoPDF = false
      }
    },
    hasStartedGeneration() {
      // console.log('hasStartedGeneration')
    },
    hasGenerated(pdfData) {
      // console.log("pdf obtenido", pdfData)
    },

    generarPDF () {
      this.$refs.html2Pdf.generatePdf()
      // console.log('generarPDF')
    },
    hasDownloaded(){
      // console.log('prueba');
      this.cargandoPDF = false
    }
  },

  //quitar el loading del boton
  watch: {
    cargandoPDF (val) {
      if (val === false) {
        this.$emit('cargandoPDF', false)
      }
    }
  }
}
</script>