<template>
    <div>
        <Tabs v-model="solicitud" :rol="rolString" :tabs="computedTabs" :send-solicitud="sendSolicitud" :is-view="isView" ref="tabSolicitud">
          <template #buttomG>
            <slot name="buttom" v-bind="$emit('input', solicitud)"/>
          </template>
        </Tabs>
    </div>
</template>
<script>
import Tabs from '../views/solicitudes/views/solicitud/component/Tabs.vue'
import Actividades from '../views/solicitudes/views/solicitud/component/Actividades.vue'
import Capacidades from '../views/solicitudes/views/solicitud/component/Capacidades.vue'
import Evaluaciones from '../views/solicitudes/views/solicitud/component/Evaluaciones.vue'
import OpcionesExtras from '../views/solicitudes/views/solicitud/component/OpcionesExtras.vue'
import Observaciones from '../views/solicitudes/views/solicitud/component/Observaciones.vue'
import ConocimientosExperiencia from '../views/solicitudes/views/solicitud/component/ConocimientosExperiencia.vue'
import SolicitudPuesto from '../views/solicitudes/views/solicitud/component/Solicitud.vue'
export default {
  props: {
    isObserved:{
      type: Boolean,
    },
    sendSolicitud:{type: Function},
    isView:{
      type: Boolean,
      default: false
    },
  },
  components: { Tabs },
  data: ()=>({
      tabs: [
      {id: 1, ref: 'solicitud_puesto', icon: 'mdi-note-text-outline', title: 'Solicitud de puesto', component: SolicitudPuesto, disabled: false, ref: 'Solicitud' },
      {id: 2, ref: 'actividades', icon: 'mdi-file-check-outline', title: 'Actividades', component: Actividades, disabled: true },
      {id: 3, ref: 'conocimiento_experiencia', icon: 'mdi-school-outline', title: 'Conocimientos y experiencia', component: ConocimientosExperiencia, disabled: true},
      {id: 4, ref: 'capacidades', icon: 'mdi-note-text-outline', title: 'Capacidades', component: Capacidades, disabled: true },
      {id: 5, ref: 'evaluaciones', icon: 'mdi-note-text-outline', title: 'Evaluaciones a realizar', component: Evaluaciones, disabled: true},
      {id: 6, ref: 'opciones_extras', icon: 'mdi-note-text-outline', title: 'Opciones extras', component: OpcionesExtras, disabled: true },      
    ],
    aditionalTab : {id: 7, ref: 'observaciones', icon: 'mdi-sticker-check-outline', title: 'Observaciones', component: Observaciones, disabled: true},
    solicitud: null,
    rolString:null,
    tabAdded: false
  }),
  computed:{
    computedTabs(){     
      if (!this.tabAdded && (this.$props.isObserved || this.solicitud?.es_observado)) {
      this.tabs.push(this.aditionalTab);
      this.tabAdded = true;
    }
    return this.isCreated ? this.tabs.map((item) => {
      return { ...item, disabled: false };
    }) : this.tabs;
    },
     
  },
  watch: {
    solicitud (val) {
      this.$emit("estado", val.id_estado_fase_solicitud)
    }
  },
  
  created(){
    this.isCreated = this.$route.params.hasOwnProperty('id')
    if(this.$route.name === 'solicitud-rrhh' || this.$route.name === 'solicitud-rrhh-ver'){
      this.rolString = 'rrhh'
    }else{
      this.rolString =  this.$route.query.rrhh ? 'rrhh' : 'unidad' ;
    }

  },
}
</script>