import Vue from "vue";
import auth from './auth.services'
import users from './user.services'
import roles from './roles.services'
import profiles from './profiles.services'
import typeRoles from './typeRoles.services'
import paths from './path.services'
import contratos from './contratos.services'
import proceso from './proceso.services'
import instituciones from "@/services/instituciones.services";
import establecimientos from "@/services/establecimientos.services";
import unidadesOrganizativas from "@/services/unidadesOrganizativas.services";
import iconos from "@/services/iconos.services";
import fases from "@/services/fases.services";
import contratacion from './contratacion.service'
import procesoEspecifico from '../services/PocesosEspecificos.services';
import ofertaPersonas from '../services/ofertaPersona.services';
import procesoContratacion from "./procesoContratacion";
import autorizacion from "./autorizacion.services"

import catalogos from "./catalogos.services";
import solicitudes from "./solicitud.services";
import plantillas from "./plantillas.services";
import loginId from './auth.services'
import plantillasCorrelativo from "./plantillasCorrelativo.services";
import nombramiento from "./nombramiento.services";
import categoriaDescuento from "./categoriaDescuento";

Vue.prototype.services = {
    auth,
    users,
    roles,
    profiles,
    typeRoles,
    paths,
    contratos,
    proceso,
    instituciones,
    establecimientos,
    unidadesOrganizativas,
    iconos,
    fases,
    contratacion,
    procesoEspecifico,
    ofertaPersonas,
    procesoContratacion,
    autorizacion,
    catalogos,
    solicitudes,
    loginId,
    plantillas,
    plantillasCorrelativo,
    nombramiento,
    categoriaDescuento
}
