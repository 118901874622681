import { http_client } from "@/plugins/http_client";

const url = "/api/v1/persona";

const getInfoPersona = async (id_persona = 0, id_solicitud = 0) =>
  await http_client(`${url}/perfil/${id_persona}/${id_solicitud}`);

export default {
  getInfoPersona,
};
