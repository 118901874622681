import {http_client} from "@/plugins/http_client";

const url = '/api/v1/contratacion/oferta-persona'
const url1 = '/api/v1'
const getOfertaPersonas = async (params = {}) => await http_client(`${url}/`, params, 'get')
const getinstitucionesProcesos = async(params)=>await http_client( `${url1}/proceso-contratacion/instituciones`,params,'get')
const postGenerarPropuesta = async (params)=>await http_client( `${url1}/contratacion/generar-propuesta`,params,'post')


export default {
    getOfertaPersonas,
    getinstitucionesProcesos,
    postGenerarPropuesta
}