import {http_client} from "@/plugins/http_client";

const url = '/api/v1'

const getPlantilla = async (params = {}) => await http_client(`${url}/plantilla-documento/`, params, 'get')

const putPlantilla = async (idPlantillas = null, params = {
    pagination: true,
    perPage: 10
}) => await http_client(`${url}/plantilla-documento/${idPlantillas}`, params, 'put')

const getMargenes = async (params = {}) => await http_client(`${url}/margenes/`, params, 'get')

const getMargen = async (idMargenes, params = {}) => await http_client(`${url}/margenes/${idMargenes}`, params, 'get')

const postMargenes = async (params = {}) => await http_client(`${url}/margenes/`, params, 'post')

const putMargenes = async (idMargenes = null, params = {}) => await http_client(`${url}/margenes/editar/${idMargenes}`, params, 'put')

const deleteMargenes = async (idMargenes = null, params = {}) => await http_client(`${url}/margenes/eliminar/${idMargenes}`, params, 'delete')

const duplicarPlantillas = async (params = {}) => await http_client(`${url}/plantilla-documento/duplicar_plantilla`, params, 'post')

export default {
    getPlantilla,
    putPlantilla,

    //margenes
    getMargenes,
    getMargen,
    postMargenes,
    putMargenes,
    deleteMargenes,
    duplicarPlantillas
}