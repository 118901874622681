<template>
  <v-container fluid>
    <app-loader v-if="loader"></app-loader>

    <div id="textEditor"></div>
  </v-container>
</template>

<script>
export default {
  name: "Plantillas",
  props: {
    rutaComponente: {
      type: String,
      default: '/contrato/plantillas/crear'
    }
  },
  data: ()=>({
    loader: true,
  }),
  methods: {
    regresar(event) {
      switch (event.data.type) {
        case 'cancelar':
          if(this.$router.history.current.name != event.data.ruta){
            this.$router.replace({name: event.data.ruta}).catch((error)=>console.log(error));
          }
          break;
        case 'Unauthorized':
          // alert('No tienes permisos para realizar esta acción');
          // this.$router.push("/login")
          break;
        case 'validateError':
          this.temporalAlert({
            show: true,
            message: event.data.messageErr,
            type: "error",
          });
          if(this.$router.history.current.name != event.data.ruta){
            this.$router.replace({name: event.data.ruta}).catch((error)=>console.log(error));
          }
          break;
        case 'warning':
        this.temporalAlert({
            show: true,
            message: event.data.messageErr,
            type: "warning",
          });
        break;
        case 'success':
        //console.log('success', event.data, this.$router);
        if(this.$router.history.current.name != event.data.ruta){
          this.$router.replace({name: event.data.ruta}).catch((error)=>console.log(error));
        }

        this.temporalAlert({
            show: true,
            message: event.data.message,
            type: "success",
          });
          break;
        case 'loader':
          this.loader = false;
          break
      }      
    }
  },
  computed:{
    carga(){
      const reactAppContainer = document.getElementById('textEditor');
      const reactApp = document.createElement('div');
      reactApp.id = 'root';
      reactApp.classList.add('d-flex');

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/textEditor/assets/index-3ffd0f2f.js';
      script.async = true;
      script.defer = true;
      script.crossOrigin = '*';

      script.onload = function () {
        window.__reactAppMounted = true;
        window.__apiUrlBase = process.env.VUE_APP_API_URL
        window.postMessage({ type: 'navigate', path: this.rutaComponente }, '*');
      };

      script.onerror = function () {
        console.error('Error al cargar el script de la aplicación de React');
      };   
      reactAppContainer.appendChild(reactApp);
      reactApp.appendChild(script);   
    }
  },
  created(){  
    if (!window.__reactAppMounted) {
      window.__reactAppMounted = false;
    } else {
      window.__reactAppMounted = true;
        location.reload();
    }     
  },
  mounted() {
    window.addEventListener('message', this.regresar);
    this.carga
  },
  beforeDestroy() {
    window.removeEventListener('message', this.regresar);
  }
};
</script>
<style>
@import url('../../public/textEditor/assets/index-beb6b36a.css');
</style>
