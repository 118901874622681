import Vue from "vue";
import "@/plugins/frontendRoles";
import routerListSolicitudes from "./views/list_aprobacion/router";
export default [
    {
        path: "/solicitudes",
        name: "solicitudes",
        component: () => import(/* webpackChunkName: "solicitudesList" */ "./layout.vue"),
        children: [
            //! ============================Listado de solicitudes============================
            {
                path: "/",
                name: "lista-solicitudes",
                meta: {rol: Vue.prototype.listRoles.solicitudes.lista },
                component: () => import(/* webpackChunkName: "solicitudesList" */ "./views/ListaSolicitudes.vue"),
            },
             //! ===========Crear/Editar/Ver de solicitudes de la unidad solicitante.===========
            {
                path: "solicitud",
                name: "solicitud-created",
                meta: {rol: Vue.prototype.listRoles.solicitudes.crear },
                component: () => import( /* webpackChunkName: "solicitud" */ "./views/solicitud/page/Solicitud.vue" )
            },
            {
                path: "solicitud/:id?/:firstOne?",
                name: "solicitud-update",
                meta: {isSolventar: true, rol: Vue.prototype.listRoles.solicitudes.editar },
                component: () => import( /* webpackChunkName: "solicitud" */ "./views/solicitud/page/Solicitud.vue" )
            },
            {
                path: "solicitud-ver/:id",
                name: "solicitud-view",
                meta:{isView:true, rol: Vue.prototype.listRoles.solicitudes.ver},
                component: () => import( /* webpackChunkName: "solicitud" */ "./views/solicitud/page/Solicitud.vue" )
            },
            //!========================== Redirección de tabs de RRHH ===========================
            {
                path: "solicitud/visto-bueno/:id",
                name: "solicitud-visto-bueno",
                meta:{isView:true, rol: Vue.prototype.listRoles.solicitudes.ver},
                component: () => import( /* webpackChunkName: "solicitud" */ "./views/solicitud/page/VistoBueno.vue" )
            },
            //! ======================== Lista de Solicitudes a aprobar =========================
            {
                path:"new-aprobacion",
                name:"new-aprobacion",
                meta: {rol: Vue.prototype.listRoles.solicitudes.jefatura_evaluar },
                component: () => import("./views/list_aprobacion/layout.vue"),
                children: routerListSolicitudes
            },
            //! ================================ Aprobaciones.===================================
            {
                path: "aprobacion/jefatura/:id",
                name: "solicitud-jefatura",
                meta: {id_fase:'P1-F2',rol: Vue.prototype.listRoles.solicitudes.jefatura_evaluar },
                component: () => import("./views/solicitud/aprobacion/pages/Jefatura.vue" )
            },
            {
                path: "aprobacion/rrhh/:id",
                name: "solicitud-rrhh",
                meta: {id_fase:'P1-F3',rol: Vue.prototype.listRoles.solicitudes.rrhh_evaluar },
                component: () => import("./views/solicitud/aprobacion/pages/RRHH.vue" )
            },
            {
                path: "aprobacion/ufi/:id",
                name: "solicitud-ufi",
                meta: {id_fase:'P1-F4',rol: Vue.prototype.listRoles.solicitudes.ufi_evaluar },
                component: () => import("./views/solicitud/aprobacion/pages/Ufi.vue" )
            },
            {
                path: "aprobacion/despacho/:id",
                name: "solicitud-despacho",
                meta: {id_fase:'P1-F5',rol: Vue.prototype.listRoles.solicitudes.despacho_evaluar },
                component: () => import("./views/solicitud/aprobacion/pages/Despacho.vue" )
            },
            {
                path: "aprobacion/dinarh/:id/establecimiento/:establecimiento",
                name: "solicitud-dinarh",
                meta: {id_fase:'P1-F6',rol: Vue.prototype.listRoles.solicitudes.dinarh_evaluar },
                component: () => import("./views/solicitud/aprobacion/pages/Dinarh.vue" )
            },
            //! ============================= Solo ver Aprobaciones=============================
            {
                path: "aprobacion/jefatura-ver/:id",
                name: "solicitud-jefatura-ver",
                meta:{isView:true, rol: Vue.prototype.listRoles.solicitudes.jefatura_evaluar},
                component: () => import("./views/solicitud/aprobacion/pages/Jefatura.vue" )
            },
            {
                path: "aprobacion/rrhh-ver/:id",
                name: "solicitud-rrhh-ver",
                meta:{isView:true, id_fase:'P1-F3' ,rol: Vue.prototype.listRoles.solicitudes.rrhh_evaluar},
                component: () => import("./views/solicitud/aprobacion/pages/RRHH.vue" )
            },
            {
                path: "aprobacion/ufi-ver/:id",
                name: "solicitud-ufi-ver",
                meta:{isView:true, rol: Vue.prototype.listRoles.solicitudes.ufi_evaluar},
                component: () => import("./views/solicitud/aprobacion/pages/Ufi.vue" )
            },
            {
                path: "aprobacion/despacho-ver/:id",
                name: "solicitud-despacho-ver",
                meta:{isView:true, rol: Vue.prototype.listRoles.solicitudes.despacho_evaluar},
                component: () => import("./views/solicitud/aprobacion/pages/Despacho.vue" )
            },
            {
                path: "aprobacion/dinarh-ver/:id/establecimiento/:establecimiento",
                name: "solicitud-dinarh-ver",
                meta:{isView:true, rol: Vue.prototype.listRoles.solicitudes.dinarh_evaluar},
                component: () => import("./views/solicitud/aprobacion/pages/Dinarh.vue" )
            },
        ]
    },
]
