<template>
    <div>
        <v-tabs
          v-model="tabs"
          fixed-tabs
          :vertical="$vuetify.breakpoint.smAndDown"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''" active-class="app-tabs-active"
          class="app-tabs" 
          background-color="transparent"
          color="1c1f4c"
          :grow="$vuetify.breakpoint.smAndDown"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            class="app-tab text-capitalize font-weight-black"
          >
            <span>RRHH</span>
          </v-tab>
          <v-tab
            class="app-tab text-capitalize font-weight-black"
          >
            <span>Otras</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
            <v-tab-item>
              <slot name="RRHH"/>
          </v-tab-item>
          <v-tab-item>
              <slot name="Otros"/>
          </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
 export default {
    data () {
      return {
        tabs: null,
      }
    },
  }
</script>