import { http_client } from "@/plugins/http_client";

const url = "/api/v1/nombramiento";
const getNombramientos = async (params = {}) =>
  await http_client(`${url}/`, params);
const updateNombramiento = async (id_nombramiento, body = {}) =>
  await http_client(`${url}/${id_nombramiento}`, body, "put");
const getNombramiento = async (id) =>
  await http_client(`${url}/${id}`, {}, "get");
const Nombramiento = async (params = {}) =>
  await http_client(url, params, "post");

export default {
  getNombramientos,
  updateNombramiento,
  getNombramiento,
  Nombramiento,
};
