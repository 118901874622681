<script>
import TablaTabs from "@/views/solicitudes/components/TablaTabs.vue";
import LineaTiempoObservaciones from "../../../components/LineaTiempoObservaciones.vue";
export default {
  name: "SolicitudObservaciones",
  components: { TablaTabs, LineaTiempoObservaciones },
  props: {
    rol: {
      type: String,
      required: true,
      default: () => "unidad",
    },
    idSoli: {
      type: Number,
      default: () => 7,
    },
  },
  data() {
    return {
      items: [
        {
          color: "red lighten-2",
          icon: "mdi-star",
        },
        {
          color: "purple darken-1",
          icon: "mdi-book-variant",
        },
        {
          color: "green lighten-1",
          icon: "mdi-airballoon",
        },
        {
          color: "indigo",
          icon: "mdi-buffer",
          descripcion: "ultimo",
        },
        {
          color: "purple darken-1",
          icon: "mdi-book-variant",
        },
        {
          color: "green lighten-1",
          icon: "mdi-airballoon",
        },
        {
          color: "indigo",
          icon: "mdi-buffer",
          descripcion: "ultimo",
        },
      ],
      years: [
        {
          color: "cyan",
          year: "1960",
        },
        {
          color: "green",
          year: "1970",
        },
        {
          color: "pink",
          year: "1980",
        },
        {
          color: "amber",
          year: "1990",
        },
        {
          color: "orange",
          year: "2000",
        },
      ],
      comentarios: [],
    };
  },
  methods: {
    async getObservaciones() {
      const response = await this.services.solicitudes.getObservaciones(
        this.$route.params.id
      );
      if (response.status === 200 && response.data.length > 0) {
        this.comentarios = response.data
      }
    },
    async init(){
      await this.getObservaciones();
    },
  },
  async created() {
    await this.init()
  },
};
</script>
<template>
  <v-row justify="center">
    <v-col cols="12" md="10" style="height: 861px">
      <p class="text-h5 font-weight-bold text-center primary&#45;&#45;text">
        Detalle
      </p>
      <div class="scroll-style">
        <linea-tiempo-observaciones :comentarios="comentarios"></linea-tiempo-observaciones>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss" scoped>
.scroll-style {
  height: 790px;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
