import Vue from "vue";
import "@/plugins/frontendRoles";
export default [
  {
    path: "jefatura",
    name: "list-aprobacion-jefatura",
    meta:{rol: Vue.prototype.listRoles.solicitudes.jefatura_lista_aprobar},
    component: () => import("./pages/Jefatura.vue"),
  },
  {
    path: "rrhh",
    name: "list-aprobacion-rrhh",
    meta:{rol: Vue.prototype.listRoles.solicitudes.rrhh_lista_aprobar},
    component: () => import("./pages/Rrhh.vue"),
  },
  {
    path: "ufi",
    name: "list-aprobacion-ufi",
    meta:{rol: Vue.prototype.listRoles.solicitudes.ufi_lista_aprobar},
    component: () => import("./pages/Ufi.vue"),
  },
  {
    path: "despacho",
    name: "list-aprobacion-despacho",
    meta:{rol: Vue.prototype.listRoles.solicitudes.despacho_lista_aprobar},
    component: () => import("./pages/Despacho.vue"),
  },
  {
    path: "dinarh",
    name: "list-aprobacion-dinarh",
    meta:{rol: Vue.prototype.listRoles.solicitudes.dinarh_lista_aprobar},
    component: () => import("./pages/Dinarh.vue"),
  },
];
