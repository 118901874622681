<template>
  <div>
    <v-data-table
        :headers="setheader"
        :items="data"
        :hide-default-footer="hideDefaultFooter"
        class="elevation-0 no-border"
    >
      <template #item.descripcion="{ value }">
        <div class="py-3" style="word-wrap: break-word;"
        :style="$vuetify.breakpoint.name !== 'xs' ? 'max-width: 40vw;' : 'max-width: 50vw;'">
          {{ value }}
        </div>
      </template>

      <template #item.nombre="{ value }">
        <div class="py-3" style="word-wrap: break-word;"
              :style="$vuetify.breakpoint.name !== 'xs' ? 'max-width: 40vw;' : 'max-width: 50vw;'">
          {{ value }}
        </div>
      </template>

      <template #item.corr_op="{ value }">
        <div>
          {{ value }}
        </div>
      </template>

      <template v-for="v in slotsColumn" #[`item.${v}`]="{item, index}">
        <slot :name="v" :item="item" :index="index"/>
      </template>
      <!--
      <template v-slot:item.activo="{ item }">
        <v-checkbox
            color="primary"
            v-model="item.activo"
            @change="inputChange($event, item)"
            :readonly="rol !== 'unidad'"
            :disabled="rol !== 'unidad'"
        ></v-checkbox>
      </template>

      <template v-slot:item.accion="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
            >
              <v-icon>{{ icon }}</v-icon>
            </v-btn>
          </template>
          <span> {{ labelDelete }} </span>
        </v-tooltip>
      </template> -->
    </v-data-table>
    <!-- <AppPagination
        :perPage.sync="por_pagina"
        :page.sync="pagina"
        :total-registros="Number(totalRegistros)"
        @input="metodoInput($event)"
        @change="metodoChange"
    ></AppPagination> -->
      <app-pagination
        v-model="numPage"
        :total-pages="total"
        @change="changePagina"
      ></app-pagination>
  </div>
</template>

<script>
import AppPagination from "./ComponentPaginationProcess.vue"

export default {
  name: "TablaTabs",
  components: {AppPagination},
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Number,
      default: 1,
    },
    totalRegistros: {
      type: Number,
      default: 0,
    },
    por_pagina: {
      type: Number,
      default: 10,
    },
    keyId: {
      type: String
    },
    icon: {
      type: String,
      default: 'mdi-trash-can-outline'
    },
    labelDelete: {
      type: String,
      default: 'Eliminar'
    },
    rol: {
      type: String,
      default: 'unidad'
    },
    slotsColumn: {
      type: Array,
      default: () => ([])
    },
    nombrePagina: {
      type: String,
      default: 'pagina'
    },
    exclude: String
  },
  data() {
    return {
      // counter: 1,
      numPage: 0
    }
  },
  methods: {

    // addCorrelativo(item) {
    //   item.num = this.counter++;
    //   return item;
    // },
    // metodoInput(event){
    //   this.$watch(()=>{
    //     if (this.$parent.$parent.hasOwnProperty(this.nombrePagina)) {
    //       this.$parent.$parent[this.nombrePagina] = event;
    //     }
    //   })
    //   this.$emit('metodoInputEjecutado');
    // },
    // metodoChange(){
    //   this.$emit('metodoChangeEjecutado');
    // },
    changePagina(nuevo, old) {
      this.$emit("paginationChange", nuevo);
    },
    // deleteItem(item) {
    //   console.log(item);
    //   this.$emit('item-deleted', item); // Emitir el evento con los datos
    // },
    // inputChange(event, item) {
    //   ;
    //   const itemObj = {
    //     id: item.id,
    //     estado: event,
    //   };
    //   this.$emit('item-changed', itemObj); // Emitir el evento con los datos
    // },
  },
  computed: {
    // modifiedTableData() {
    //   return this.data.map(this.addCorrelativo);
    // }
    total() {
      return Math.ceil(this.totalRegistros / this.por_pagina)
    },
    setheader(){
      return this.headers.filter((item)=> item.value != this.exclude)
    }
  },
  watch: {
    data: function (nuevo, anterior) {
      if(nuevo.length === 0 && this.numPage !== 1){
        this.numPage= 1
      }
    }
  },
  created() {
    this.numPage = this.page
  },
}
</script>

<style lang="scss" scoped>
:deep(tbody tr:nth-of-type(odd)) {
  background-color: #eeeeee
}
</style>
