<script>
import CardList from "@/views/solicitudes/components/CardList.vue";
import TablaTabs from "@/views/solicitudes/components/TablaTabs.vue";

export default {
  name: "ConocimientosExperiencia",
  components: { TablaTabs, CardList},
  props: {
    rol: {
      type: String,
      required: true,
      default: () => 'unidad'
    },
    idSoli: {
      type: Number,
      default: () => 7
    },
    isView: {
      type: Boolean,
    },
  },
  data() {
    return {
      listMenu: [
        {
          id: 1,
          name: 'Formación',
          icon: 'mdi-school-outline',
          tag: 'formacion'
        },
        {
          id: 2,
          name: 'Conocimientos',
          icon: 'mdi-school-outline',
          tag: 'conocimientos'
        },
        {
          id: 3,
          name: 'Experiencia previa',
          icon: 'mdi-briefcase-clock-outline',
          tag: 'experiencia'
        }
      ],
      paginacionF: {
        pagina: 1,
        por_pagina: 3,
        total_registro: 0
      },
      paginacionC: {
        pagina: 1,
        por_pagina: 3,
        total_registro: 0
      },
      paginacionE: {
        pagina: 1,
        por_pagina: 3,
        total_registro: 0
      },
      paginacionOtrosF: {
        pagina: 1,
        por_pagina: 3,
        total_registro: 0
      },
      paginacionOtrosC: {
        pagina: 1,
        por_pagina: 3,
        total_registro: 0
      },
      paginacionOtrosE: {
        pagina: 1,
        por_pagina: 3,
        total_registro: 0
      },
      headersFormacion: [
        {
          text: 'Mostrar en oferta',
          value: 'activo',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'start',
          width: '35%',
        },
        {
          text: 'N°',
          value: 'correlativo',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'start',
          width: '20%',
        },
        {
          text: 'Descripción',
          value: 'nombre',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'left'
        },
        {
          text: 'Acciones',
          value: 'accion',
          class: 'black--text py-2 font-weight-bold text-subtitle-1 text-center',
          align: 'center'
        },
      ],
      headersConocimientos: [
        {
          text: 'Mostrar en oferta',
          value: 'activo',
          width: '35%',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',

        },
        {
          text: 'N°',
          value: 'correlativo',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          width: '20%',
        },
        {
          text: 'Descripción de actividad',
          value: 'nombre',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'left'
        },
        /*{
          text: 'Nivel',
          value: 'nivel',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'center'
        },*/
        {
          text: 'Acciones',
          value: 'accion',
          class: 'black--text py-2 font-weight-bold text-subtitle-1 text-center',
          align: 'center'
        },
      ],
      headersExperiencia: [
        {
          text: 'Mostrar en oferta',
          value: 'activo',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          width: '35%',
        },
        {
          text: 'N°',
          value: 'correlativo',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          width: '20%',
        },
        {
          text: 'Experiencia',
          value: 'nombre',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'left'
        },
        {
          text: 'Años de experiencia',
          value: 'anios',
          class: 'black--text py-2 font-weight-bold text-subtitle-1',
          align: 'center'
        },
        {
          text: 'Acciones',
          value: 'accion',
          class: 'black--text py-2 font-weight-bold text-subtitle-1 text-center',
          align: 'center'
        },
      ],
      itemsFormacion: [],
      itemsConocimientos: [],
      itemsExperiencia: [],
      itemsFormacionOtros: [],
      itemsConocimientosOtros: [],
      itemsExperienciaOtros: [],
      formacion: [],
      conocimiento: [],
      experiencia: [],
      nivelExperiencia: [],
      searchFormacion: null,
      searchConocimiento: null,
      searchExperiencia: null,
      id_solicitud: null,
      id_formacion: null,
      id_conocimiento: null,
      id_experiencia: null,
      aniosExperiencia: null,
    }
  },
  methods: {
    // handleItemChanged(itemObj){
    //   this.$emit('updatedData', {
    //     type: 'Conocimientos',
    //     parameters: {
    //       id: itemObj.id,
    //       estado: itemObj.estado
    //     }
    //   })
    // }
    async getFormacion() {
      let params = {
        cantidad_por_pagina: this.paginacionF.por_pagina,
        pagina: this.paginacionF.pagina,
      }
      const { data } = await this.services.catalogos.getCatalogo(`formacion/${this.idSoli}`, params);
      if (Array.isArray(data.data)) {
        this.itemsFormacion = data.data.map((x, index) => {
          x.correlativo = index + 1;
          return x
        });
      } else {
        this.itemsFormacion = []
      }
      this.$nextTick(() => {
        this.paginacionF.pagina = Number(data.meta.pagina)
        this.paginacionF.por_pagina = Number(data.meta.cantidad_por_pagina)
        this.paginacionF.total_registro = Number(data.meta.total)
      })
      for (let i = 0; i < this.itemsFormacion.length; i++) {
        this.$set(this.itemsFormacion[i], 'activo', false);
      }
      this.getFormacionAsignadas()
    },
    async getConocimiento() {
      let params = {
        cantidad_por_pagina: this.paginacionC.por_pagina,
        pagina: this.paginacionC.pagina,
      }
      const { data } = await this.services.catalogos.getCatalogo(`conocimientos/${this.idSoli}`, params);
      if (Array.isArray(data.data)) {
        this.itemsConocimientos = data.data.map((x, index) => {
          x.correlativo = index + 1;
          return x
        });

      } else {
        this.itemsConocimientos = []
      }
      this.$nextTick(() => {
        this.paginacionC.pagina = Number(data.meta.pagina)
        this.paginacionC.por_pagina = Number(data.meta.cantidad_por_pagina)
        this.paginacionC.total_registro = Number(data.meta.total)
      })
      for (let i = 0; i < this.itemsConocimientos.length; i++) {
        this.$set(this.itemsConocimientos[i], 'activo', false);
      }
      this.getConocimientosAsignados()
    },
    async getExperiencia() {
      let params = {
        cantidad_por_pagina: this.paginacionE.por_pagina,
        pagina: this.paginacionE.pagina,
      }
      const { data } = await this.services.catalogos.getCatalogo(`experiencia/${this.idSoli}`, params);
      if (Array.isArray(data.data)) {
        this.itemsExperiencia = data.data.map((x, index) => {
          x.correlativo = index + 1;
          return x
        });
      } else {
        this.itemsExperiencia = []
      }
      this.$nextTick(() => {
        this.paginacionE.pagina = Number(data.meta.pagina)
        this.paginacionE.por_pagina = Number(data.meta.cantidad_por_pagina)
        this.paginacionE.total_registro = Number(data.meta.total)
      })
      for (let i = 0; i < this.itemsExperiencia.length; i++) {
        this.$set(this.itemsExperiencia[i], 'activo', false);
      }
      this.getExperienciaAsignadas()
    },
    async getFormacionAsignadas() {
      const response = await this.services.catalogos.getFormacionAsig(this.$route.params.id)
      let idArreglo = response.data.ids
      for (let i = 0; i < this.itemsFormacion.length; i++) {
        const objeto = this.itemsFormacion[i];
        if (idArreglo.includes(objeto.id)) {
          this.$set(objeto, 'activo', true);
        }
      }
    },
    async getConocimientosAsignados() {
      const response = await this.services.catalogos.getConocimientoAsig(this.$route.params.id)
      let idArreglo = response.data
      for (let i = 0; i < this.itemsConocimientos.length; i++) {
        const objeto = this.itemsConocimientos[i];
        if (idArreglo.includes(objeto.id)) {
          this.$set(objeto, 'activo', true);
        }
      }
    },
    async updateExperienciaList() {
        let params = {
            tipo: 'experiencia',
            buscar: this.searchExperiencia
        };

        const response = await this.services.solicitudes.getPuestoRequisito(this.id_solicitud, params);
        this.experiencia = response.data;
    },
    async updateConocimientoList() {
        let params = {
            tipo: 'conocimiento',
            buscar: this.searchConocimiento
        };

        const response = await this.services.solicitudes.getPuestoRequisito(this.id_solicitud, params);
        this.conocimiento = response.data;
    },
    async updateFormacionList() {
        let params = {
            tipo: 'formacion',
            buscar: this.searchFormacion
        };

        const response = await this.services.solicitudes.getPuestoRequisito(this.id_solicitud, params);
        this.formacion = response.data;
    },
    async getExperienciaAsignadas() {
      const response = await this.services.catalogos.getExperienciaAsig(this.$route.params.id)
      let idArreglo = response.data
      for (let i = 0; i < this.itemsExperiencia.length; i++) {
        const objeto = this.itemsExperiencia[i];
        if (idArreglo.includes(objeto.id)) {
          this.$set(objeto, 'activo', true);
        }
      }
    },
    async setPaginacionFormacion(pagina) {
      this.paginacionF.pagina = pagina
      await this.getFormacion()
    },
    async setPaginacionConocimiento(pagina) {
      this.paginacionC.pagina = pagina
      await this.getConocimiento()
    },
    async setPaginacionExperiencia(pagina) {
      this.paginacionE.pagina = pagina
      await this.getExperiencia()
    },
    async inputChange(event, item, tipo) {
      if (tipo === 'formacion') {
        let body = {
          id_solicitud: Number(this.$route.params.id),
          id_catalogo: item.id
        }
        const response = await this.services.catalogos.postCatalogo('formacion', body);
        if (response.status === 200 || response.status === 201) {
          this.getFormacion();
        }
      } else if (tipo === 'conocimiento') {
        let body = {
          id_solicitud: Number(this.$route.params.id),
          id_catalogo: item.id
        }
        const response = await this.services.catalogos.postCatalogo('conocimientos', body);
        if (response.status === 200 || response.status === 201) {
          this.getConocimiento();
        }
      } else if (tipo === 'experiencia') {
        let body = {
          id_solicitud: Number(this.$route.params.id),
          id_catalogo: item.id
        }
        const response = await this.services.catalogos.postCatalogo('experiencia', body);
        if (response.status === 200 || response.status === 201) {
          this.getExperiencia();
        }
      }
    },
    async timeout(ms=500){
      if(this.timeOut) clearTimeout(this.timeOut);
      return new Promise((resolve)=>{
         this.timeOut = setTimeout(resolve, ms)
      })
    },

    async searchItem(typeSearch, typing= false, search) {
      let params= {
        tipo: typeSearch,
        buscar: this[search]
      }

      const response = await this.services.solicitudes.getPuestoRequisito(this.id_solicitud, params)
      this[typeSearch]= response.data
      this[search] = null
    },
    async addKnowledgeAndExperiences(typerequirement, KnowledgeAndExperiences, updateTable){
      if(!this.$refs[typerequirement].validate()) return

      let params= {
        tipo: typerequirement,
        id_requisito: this[KnowledgeAndExperiences]
      }
      if(typerequirement == 'experiencia') params.id_nivel_experiencia = this.aniosExperiencia

      const response = await this.services.solicitudes.postPuestoRequisito(this.id_solicitud, params)
      if(response.status == 200 || response.status == 201){
        await this.joinKnowledgeAndExperiences(updateTable);
      }
      this.$refs[typerequirement].reset()  
      },
    async deleteKnowledgeAndExperiences(typerequirement,{id}, updateTable){
      let params= {
        tipo: typerequirement,
        id_requisito: id
      }
      const response = await this.services.solicitudes.deletePuestoRequisito(this.id_solicitud, params)
      if(response.status == 200 || response.status == 201){
        await this.joinKnowledgeAndExperiences(updateTable);
      }
    },
    async joinKnowledgeAndExperiences(
      join={
        formacion: ['itemsFormacionOtros', 'paginacionOtrosF'],
        conocimiento: ['itemsConocimientosOtros','paginacionOtrosC'],
        experiencia: ['itemsExperienciaOtros', 'paginacionOtrosE']
        }
      ){
      let params = {}
      for (const key in join) {
        params.cantidad_por_pagina = this[join[key][1]].por_pagina
        params.pagina = this[join[key][1]].pagina
        params.tipo= key
        const { data: {data, meta} } = await this.services.solicitudes.getConocimientosYExperiencias(this.id_solicitud, params)
          this[join[key][0]]= data
          this.$nextTick(() => {
          this[join[key][1]].pagina = Number(meta.pagina)
          this[join[key][1]].por_pagina = Number(meta.cantidad_por_pagina)
          this[join[key][1]].total_registro = Number(meta.total)
      })
      }
    },
    setPaginacionFOtros(pagina){
      this.paginacionOtrosF.pagina = pagina
      this.joinKnowledgeAndExperiences({formacion: ['itemsFormacionOtros', 'paginacionOtrosF']})
    },
    setPaginacionCOtros(pagina){
      this.paginacionOtrosC.pagina = pagina
      this.joinKnowledgeAndExperiences({conocimiento: ['itemsConocimientosOtros','paginacionOtrosC']})
    },
    setPaginacionEOtros(pagina){
      this.paginacionOtrosE.pagina = pagina
      this.joinKnowledgeAndExperiences({experiencia: ['itemsExperienciaOtros', 'paginacionOtrosE']})
    },
    async init(){
      this.id_solicitud = this.$route.params.id
      await Promise.race([
        this.getFormacion(),
        this.getConocimiento(),
        this.getExperiencia(),
        this.searchItem('formacion'),
        this.searchItem('conocimiento'),
        this.searchItem('experiencia'),
        this.joinKnowledgeAndExperiences(),
        this.services.catalogos.getNivelExperiencia().then(({data})=>{
          this.nivelExperiencia= data;
        })
    ])
    }
  },
  async created() {
    await this.init()
  },
}
</script>

<template>
  <v-row>
    <v-col cols="12" md="3">
      <card-list :items="listMenu" class="text-center"></card-list>
    </v-col>
    
    <v-col cols="12" md="9" class="px-md-15"> 
      <p class="text-h5 font-weight-bold primary--text" ref="formacion">Formación</p>
      <app-tabs>
        <template #RRHH>
          <v-row>        
            <v-col class="px-sm-3 px-1">
              <v-card class="my-6 pa-sm-4 " rounded="lg" elevation="5">
                <tabla-tabs :key-id="'id'" :data="itemsFormacion" :headers="headersFormacion"
                  :por_pagina="paginacionF.por_pagina" :page="paginacionF.pagina"
                  :total-registros="paginacionF.total_registro" :slots-column="['activo', 'correlativo']"
                  @paginationChange="setPaginacionFormacion" :rol="rol" nombre-pagina="pf"
                  exclude="accion">
                  <template #correlativo="{item, index}">
                    <div>
                      <span>{{ ++index }}</span>
                    </div>
                  </template>
                  <template #[`activo`]="{ item }">
                    <v-checkbox class="ml-10" color="primary" v-model="item.activo"
                      @change="inputChange($event, item, 'formacion')" :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
                      :disabled="!['unidad', 'rrhh'].includes(rol) || isView"></v-checkbox>
                  </template>
                </tabla-tabs>
              </v-card>
            </v-col>
           </v-row>
        </template>
        <template #Otros>
          <v-form ref="formacion">
            <v-row class="pt-5">
            <v-col cols="12" sm="7" lg="5">
              <div class="d-flex flex-direction-row">
                <v-autocomplete
                :search-input.sync="searchFormacion"
                :items="formacion"
                item-value="id"
                item-text="nombre"
                v-model="id_formacion"
                dense
                label="Formación"
                no-data-text="Sin resultados"
                class="rounded-xl"
                :rules="[v => !!v || 'Formación es requerida']"
                outlined
                :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
                @click="updateFormacionList"
                @keyup="searchItem('formacion', 'searchFormacion', true)">
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="6" sm="2" class="d-flex justify-start">
              <v-btn 
                  class="btn-text px-5"
                  color="primary"
                  rounded
                  small
                  :disabled="!['unidad', 'rrhh'].includes(rol) || isView"
                  @click="addKnowledgeAndExperiences('formacion', 'id_formacion', { formacion: ['itemsFormacionOtros', 'paginacionOtrosF']})"
                ><strong >Agregar</strong></v-btn>
            </v-col>
           </v-row>
          </v-form>
           <v-row>        
            <v-col class="px-sm-3 px-1">
              <v-card class="my-6 pa-sm-4 " rounded="lg" elevation="5">
                <tabla-tabs :key-id="'id'" :data="itemsFormacionOtros" :headers="headersFormacion"
                  :por_pagina="paginacionOtrosF.por_pagina" :page="paginacionOtrosF.pagina"
                  :total-registros="paginacionOtrosF.total_registro" :slots-column="['accion','correlativo']"
                  @paginationChange="setPaginacionFOtros" :rol="rol" nombre-pagina="pf"
                  exclude="activo">
                  <template #[`correlativo`]="{item, index}">
                    <div>
                      <span>{{ ++index }}</span>
                    </div>
                  </template>
                  <template #[`accion`]="{ item }" >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            color="primary"
                            :disabled="!['unidad', 'rrhh'].includes(rol) || isView"
                            :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
                            @click="deleteKnowledgeAndExperiences('formacion', item, { formacion: ['itemsFormacionOtros', 'paginacionOtrosF']})"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span> Eliminar </span>
                    </v-tooltip>
                  </template>
                </tabla-tabs>
              </v-card>
            </v-col>
           </v-row>
        </template>
        
      </app-tabs>      
      
      <p class="text-h5 font-weight-bold primary--text" ref="conocimientos">Conocimientos</p>
      <app-tabs>
        <template #RRHH>
          <v-row>
           <v-col class="px-sm-3 px-1">
            <v-card class="my-6 pa-sm-4" rounded=" lg" elevation="5">
              <tabla-tabs :key-id="'id'" :data="itemsConocimientos" :headers="headersConocimientos"
                :por_pagina="paginacionC.por_pagina" :page="paginacionC.pagina"
                :total-registros="paginacionC.total_registro" :slots-column="['activo','correlativo']"
                @paginationChange="setPaginacionConocimiento" :rol="rol" nombre-pagina="pc"
                exclude="accion">
                <template #correlativo="{item, index}">
                    <div>
                      <span>{{ ++index }}</span>
                    </div>
                </template>
                <template #[`activo`]="{ item }">
                  <v-checkbox class="ml-10" color="primary" v-model="item.activo"
                    @change="inputChange($event, item, 'conocimiento')" :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
                    :disabled="!['unidad', 'rrhh'].includes(rol) || isView"></v-checkbox>
                </template>
              </tabla-tabs>
            </v-card>
           </v-col>
          </v-row>
        </template>
        <template #Otros>
          <v-form ref="conocimiento">
            <v-row class="pt-5">
          <v-col cols="12" sm="7" lg="5">
            <div class="d-flex flex-direction-row">
              <v-autocomplete
              :search-input.sync="searchConocimiento"
              :items="conocimiento"
              item-value="id"
              item-text="nombre"
              v-model="id_conocimiento"
              label="Conocimientos"
              no-data-text="Sin resultados"
              dense
              class="rounded-xl"
              :rules="[v => !!v || 'Conocimiento es requerido']"
              :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
              outlined
              @click="updateConocimientoList"
              @keyup="searchItem('conocimiento', 'searchConocimiento', true)"
              ></v-autocomplete>
        </div>
          </v-col>
          <v-col cols="6" sm="2" class="d-flex justify-start">
            <v-btn 
                class="btn-text px-5"
                color="primary"
                rounded
                small
                :disabled="!['unidad', 'rrhh'].includes(rol) || isView"
                @click="addKnowledgeAndExperiences('conocimiento', 'id_conocimiento', { conocimiento: ['itemsConocimientosOtros','paginacionOtrosC'] })"
              ><strong>Agregar</strong></v-btn>
          </v-col>
        </v-row>
          </v-form>
        <v-row>
          <v-col class="px-sm-3 px-1">
            <v-card class="my-6 pa-sm-4" rounded=" lg" elevation="5">
              <tabla-tabs :key-id="'id'" :data="itemsConocimientosOtros" :headers="headersConocimientos"
                :por_pagina="paginacionOtrosC.por_pagina" :page="paginacionOtrosC.pagina"
                :total-registros="paginacionOtrosC.total_registro" :slots-column="['accion', 'correlativo']"
                @paginationChange="setPaginacionCOtros" :rol="rol" nombre-pagina="pc"
                exclude="activo">
                <template #correlativo="{item, index}">
                    <div>
                      <span>{{ ++index }}</span>
                    </div>
                </template>
                <template #[`accion`]="{ item }" >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          :disabled="!['unidad', 'rrhh'].includes(rol) || isView"
                          :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
                          @click="deleteKnowledgeAndExperiences('conocimiento', item, { conocimiento: ['itemsConocimientosOtros','paginacionOtrosC'] })"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span> Eliminar </span>
                  </v-tooltip>
                </template>
              </tabla-tabs>
            </v-card>
          </v-col>
        </v-row>
        </template>
      </app-tabs>
      <p class="text-h5 font-weight-bold primary--text" ref="experiencia">Experiencia previa</p>
      <app-tabs>
        <template #RRHH>
          <v-row>
          <v-col class="px-sm-3 px-1" >
            <v-card class="my-6 pa-sm-4" rounded="lg" elevation="5">
              <tabla-tabs :key-id="'id'" :data="itemsExperiencia" :headers="headersExperiencia"
                :por_pagina="paginacionE.por_pagina" :page="paginacionE.pagina"
                :total-registros="paginacionE.total_registro" :slots-column="['activo', 'correlativo']"
                @paginationChange="setPaginacionExperiencia" :rol="rol" nombre-pagina="pe"
                exclude="accion">
                <template #correlativo="{item, index}">
                    <div>
                      <span>{{ ++index }}</span>
                    </div>
                </template>
                <template #[`activo`]="{ item }">
                  <v-checkbox class="ml-10" color="primary" v-model="item.activo"
                    @change="inputChange($event, item, 'experiencia')" :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
                    :disabled="!['unidad', 'rrhh'].includes(rol) || isView"></v-checkbox>
                </template>
              </tabla-tabs>
            </v-card>
          </v-col>
        </v-row>
        </template>
        <template #Otros>
          <v-form ref="experiencia">
            <v-row class="pt-5">
          <v-col cols="12" sm="4" lg="4">
            <div class="d-flex flex-direction-row">
              <v-autocomplete
              :search-input.sync="searchExperiencia"
              :items="experiencia"
              item-value="id"
              item-text="nombre"
              v-model="id_experiencia"
              label="Experiencia"
              no-data-text="Sin resultados"
              dense
              class="rounded-xl"
              :rules="[v => !!v || 'Experiencia es requerida']"
              outlined
              :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
              @click="updateExperienciaList"
              @keyup="searchItem('experiencia', 'searchExperiencia', true)"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col cols="12" sm="4" lg="4">
            <div class="d-flex flex-direction-row">
              <v-autocomplete
              :items="nivelExperiencia"
              item-value="id"
              item-text="anios"
              v-model="aniosExperiencia"
              label="Años de experiencia"
              no-data-text="Sin resultados"
              dense
              class="rounded-xl"
              :rules="[v => !!v || 'Años de experiencia es requerido']"
              outlined
              :disabled="!id_experiencia"
              :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col cols="6" sm="2" class="d-flex justify-start">
            <v-btn 
                class="btn-text px-5"
                color="primary"
                rounded
                small
                :disabled="!['unidad', 'rrhh'].includes(rol) || isView"
                @click="addKnowledgeAndExperiences('experiencia', 'id_experiencia', { experiencia: ['itemsExperienciaOtros', 'paginacionOtrosE'] })"
              ><strong>Agregar</strong></v-btn>
          </v-col>
        </v-row>
          </v-form>
        <v-row>
          <v-col class="px-sm-3 px-1" >
            <v-card class="my-6 pa-sm-4" rounded="lg" elevation="5">
              <tabla-tabs :key-id="'id'" :data="itemsExperienciaOtros" :headers="headersExperiencia"
                :por_pagina="paginacionOtrosE.por_pagina" :page="paginacionOtrosE.pagina"
                :total-registros="paginacionOtrosE.total_registro" :slots-column="['accion', 'correlativo']"
                @paginationChange="setPaginacionEOtros" :rol="rol" nombre-pagina="pe"
                exclude="activo">
                <template #correlativo="{item, index}">
                    <div>
                      <span>{{ ++index }}</span>
                    </div>
                </template>
                <template #[`accion`]="{ item }" >
                  <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          :disabled="!['unidad', 'rrhh'].includes(rol) || isView"
                          :readonly="!['unidad', 'rrhh'].includes(rol) || isView"
                          @click="deleteKnowledgeAndExperiences('experiencia', item, {experiencia: ['itemsExperienciaOtros', 'paginacionOtrosE']})"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span> Eliminar </span>
                  </v-tooltip>
                </template>
              </tabla-tabs>
            </v-card>
          </v-col>
        </v-row>
        </template>
      </app-tabs>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss"></style>
