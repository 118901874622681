<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-h5 font-weight-bold primary--text" ref="evaluaciones">Evaluaciones </p>
      </v-col>
      <!--      <v-col cols="12">-->
      <!--        <v-btn @click="$emit('updatedData', {method: 'Evaluaciones', opts: {params: 1}})">Emitir data</v-btn>        -->
      <!--      </v-col>-->
    </v-row>
    <v-row :justify="isView ? 'center': 'end'">
      <v-col cols="12" md="5" v-if="!isView">
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="formEvaluacion.pruebaRealizar"
                class="rounded-xl"
                label="Prueba técnica a realizar*"
                outlined
                maxlength="51"
                @blur="$v.formEvaluacion.pruebaRealizar.$touch()"
                :error-messages="pruebaRealizarErrors"
                :readonly="isReadonly || isView"
                :disabled="isReadonly "
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model="formEvaluacion.porcentajePrueba"
                class="inputStyle rounded-xl"
                label="Porcentaje a aplicar a la prueba*"
                outlined
                maxlength="3"
                @keypress="restrictInteger"
                @paste="onPasteInteger"
                @blur="$v.formEvaluacion.porcentajePrueba.$touch()"
                :error-messages="porcentajePruebaErrors"
                :readonly="(rol !== 'rrhh' && rol !== 'unidad') || isView"
                :disabled="rol !== 'rrhh' && rol !== 'unidad'"
                required
                type="text" inputmode="numeric" 
                min="0.01"  
              ></v-text-field>

          </v-col>
          <v-col cols="12">
            <v-textarea
                v-model="formEvaluacion.descripcion"
                class="rounded-xl"
                outlined
                label="Descripción*"
                @blur="$v.formEvaluacion.descripcion.$touch()"
                :error-messages="descripcionErrors"
                :readonly="isReadonly || isView"
                :disabled="isReadonly"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" rounded :disabled="isView" depressed @click="actionEvaluacion" style="text-transform: none;width: 100px;">{{ textBtn(rol) }}</v-btn>
            <v-btn v-if="textBtn(rol) == 'Actualizar'" class="ml-5" color="primary" outlined rounded :disabled="isView" depressed @click="clearFormEva" style="width: 100px;text-transform: none;">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="7" class="px-sm-3 px-1">
        <v-card class="pa-4" rounded="lg" elevation="5">
          <v-data-table
              :headers="headers"
              :items="evaluacion"
              hide-default-footer
              class="elevation-0"
              item-key="index"
          >
            <template #[`item.num`]="{item,index}">
              <div>{{ numItem(index + 1) }}</div>
            </template>
            <template #item.prueba="{ value }">
              <div class="py-3" style="word-wrap: break-word;"
                   :style="$vuetify.breakpoint.name !== 'xs' ? 'max-width: 40vw;' : 'max-width: 50vw;'">
                {{ value }}
              </div>
            </template>
            <template #[`item.porcentaje_relativo`]="{item}">
              <td class="text-center">
                {{ (item.porcentaje_relativo) }}
              </td>
            </template>
            <template #[`item.porcentaje_total`]="{item}">
              <td class="text-center">
                {{ item.ley !== true ? (item.porcentaje_total) : (item.porcentaje) }}
              </td>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-tooltip bottom v-if=" (rol === 'rrhh' && (item.agregado_rrhh || item.id_evaluacion_ley ) ) || rol == 'unidad' && (!item.agregado_rrhh && !item?.id_evaluacion_ley)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      @click="editarItem(item)"
                      :disabled="isView"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span> Editar </span>
              </v-tooltip>
                </v-col>
                <v-col cols="6">
                <v-tooltip bottom v-if="((rol === 'unidad' && !item.agregado_rrhh) || (rol === 'rrhh' && item.agregado_rrhh)) && item.ley !== true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      :disabled="isView"
                      @click="deleteItem(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span> Eliminar </span>
              </v-tooltip>
              </v-col>
              </v-row>
            </template>
          </v-data-table>
          <!-- <AppPagination
              :perPage.sync="por_pagina"
              :page.sync="pagina"
              :total-registros="Number(totalRegistros)"
          ></AppPagination> -->
          <app-pagination
            v-model="numPage"
            :total-pages="total"
            @change="changePagina"
          ></app-pagination>
        </v-card>
        <p class="d-flex justify-center black--text py-5 font-weight-bold text-subtitle-2" v-if="false">Total: {{
            (totalPorcentaje)
          }}%</p>
      </v-col>
      <Dialogo
          :visible="dialogShow"
          @close="closeDialog"
          @guardar="eliminarItem"
          title="Eliminar evaluación"
          label="¿Desea eliminar la evaluación seleccionada?"
          label-btn="Eliminar"
      />
    </v-row>
  </div>
</template>

<script>
import {maxValue, minValue, numeric, required, requiredIf, maxLength} from "vuelidate/lib/validators";
import AppPagination from "../../../components/ComponentPaginationProcess.vue"
import Dialogo from "../../../components/Dialogo.vue"

export default {
  name: "Evaluaciones",
  components: {Dialogo, AppPagination},
  props: {
    rol: {
      type: String,
      required: true,
      default: () => 'unidad'
    },
    idSoli: {
      type: Number,
      default: () => 7
    },
    isView:{
      type: Boolean,
    },
  },
  validations() {
    const notEmojis = (value) => {
      if (!value) return false
      const x = value.match(
        /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu
      );
      if (x != null) {
        return false;
      } else {
        return true;
      }
    };
    const esAlphanumericoConTildes = (value) => {
   if (!value) return false;
   const regex = /[^a-zA-ZáéíóúüÁÉÍÓÚÜñ\s]/;
       return !regex.test(value);
    };
    const esAlphanumericoyCaracterEspecial = (value) => {
    if (!value) return false;
    const regex = /[^a-zA-Z0-9áéíóúüÁÉÍÓÚÜñ\s!@#$%^&*()_\-+=<>?,.;:/[\]{}|\\'"`~`¡¿+-¯´¨©®¯±°-]/;
    return !regex.test(value);
   };
    return {
      formEvaluacion: {
        pruebaRealizar: {
          required,
          notEmojis,
          esAlphanumericoConTildes,
          maxLength: maxLength(50),
        },
        porcentajePrueba: {
          required,
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(100),
          // isComplete(value) {
          //   return (Number(value) + this.totalPorcentaje) <= 100;
          // },
        },
        descripcion: {
          required,
          notEmojis,
          esAlphanumericoyCaracterEspecial,
          maxLength: maxLength(500),
        }
      }
    }
  },
  data() {
    return {
      numPage: 0,
      formEvaluacion: {
        pruebaRealizar: null,
        porcentajePrueba: null,
        descripcion: null,
      },
      headers: [
        {text: 'N°', align: 'center', value: 'num', width:'20%',class: 'black--text py-5 font-weight-bold text-subtitle-2', sortable: false},
        {
          text: 'Prueba a realizar',
          align: 'left',
          value: 'nombre',
          class: 'black--text py-5 font-weight-bold text-subtitle-2',
          sortable: false
        },
        {
          text: 'Porcentaje unidad(%)',
          value: 'porcentaje_relativo',
          align: 'center',
          class: 'black--text py-5 font-weight-bold text-subtitle-2',
          sortable: false
        },
        {
          text: 'Porcentaje RRHH(%)',
          value: 'porcentaje_total',
          align: 'center',
          class: 'black--text py-5 font-weight-bold text-subtitle-2',
          sortable: false
        },
        {
          text: 'Acciones',
          value: 'action',
          align: 'center',
          class: 'black--text py-5 font-weight-bold text-subtitle-2',
          sortable: false
        },
      ],
      evaluacion: [],
      counter: 1,
      por_pagina: 5,
      pagina: 1,
      totalRegistros: 0,
      item: null,
      dialogShow: false,
      action: null,
    }
  },
  methods: {
    async init(){
      await this.getEvaluacion(),
      this.numPage = this.pagina
    },
    deleteItem(item) {
      this.item = item;
      this.action = 'borrar'
      this.dialogShow = true;
    },
    closeDialog() {
      this.dialogShow = false;
    },
    async eliminarItem() {
      const response = await this.services.catalogos.deleteEvaluacion(this.item.id, { rrhh: this.rol == 'rrhh' ? 'true' : 'false'});
      if(response.status === 200){
        this.temporalAlert({
            show: true,
            message: response.data.message || "Se eliminó con éxito",
            type: "success",
          });
      }
      this.getEvaluacion();
      this.dialogShow = false;
      this.clearFormEva();
    },
    addCorrelativo(item) {
      item.num = this.counter++;
      return item;
    },
    clearFormEva() {
      this.formEvaluacion.pruebaRealizar = null;
      this.formEvaluacion.porcentajePrueba = null;
      this.formEvaluacion.descripcion = null;
      this.$v.formEvaluacion.$reset();
      this.item = null;
    },
    async guardarEvaluacion() {
      this.$v.formEvaluacion.$touch()
      if (!this.$v.formEvaluacion.$invalid) {
        try {
          const form = {
            id_solicitud: Number(this.$route.params.id),
            porcentaje_relativo: this.rol === 'unidad' ? Number(Number(this.formEvaluacion.porcentajePrueba)) : 0,
            porcentaje_total: this.rol === 'rrhh' ? Number(Number(this.formEvaluacion.porcentajePrueba)) : 0,
            nombre: this.formEvaluacion.pruebaRealizar,
            descripcion: this.formEvaluacion.descripcion,
            unidad: this.rol === 'unidad' ? true : false,
            rrhh: this.rol === 'rrhh' ? true : false,
          }
          await this.services.catalogos.postEvaluacion(form)
          this.clearFormEva();
          this.getEvaluacion()
        } catch (e) {
          this.temporalAlert({
            show: true,
            message: e.response.data.message
                || e.response.data.description
                || e.response.data.error
                || "Evaluación no se puede guardar",
            type: "error",
          });
        }
      }
    },
    async actualizarEvaluacion() {
      try {

        
        if (!this.formEvaluacion.porcentajePrueba || this.formEvaluacion.porcentajePrueba <= 0) {
          this.$v.formEvaluacion.porcentajePrueba.$touch();
          return;
        }
        const total = (Number(this.formEvaluacion.porcentajePrueba) + this.totalPorcentaje)

        let body = {
          nombre: this.formEvaluacion.pruebaRealizar,
          descripcion: this.formEvaluacion.descripcion,
          porcentaje_total: this.rol === 'rrhh' ? Number(this.formEvaluacion.porcentajePrueba) : 0,
          porcentaje_relativo: this.rol === 'unidad' ? Number(this.formEvaluacion.porcentajePrueba) : 0,
          unidad: this.rol === 'unidad' ? true : false,
         	evaluacionley: this.item.ley === true ? true : false,
          rrhh: this.rol == 'unidad' ? false : true,
        }
        const response = await this.services.catalogos.putEvaluacion(this.item.id, body)

        if (response.status === 200)
          this.temporalAlert({
            show: true,
            message: "Evaluación se actualizó con éxito",
            type: "success",
          });
        this.getEvaluacion()
        this.clearFormEva()
      } catch (e) {
        this.temporalAlert({
          show: true,
          message: e.response?.data?.message 
          || e.message 
          || 'Evaluación no se puede actualizar',
          type: 'error',
        });
      }
    },

    async actionEvaluacion() {
      if (this.rol === 'unidad') {
        if(this.item){
          await this.actualizarEvaluacion()
        }else{
          await this.guardarEvaluacion()
        }
      } else if (this.rol === 'rrhh') {
        if(this.item){
          await this.actualizarEvaluacion()
        }else{
          await this.guardarEvaluacion()
        }
      }
    },
    async getEvaluacion() {
      const params = {
        pagina: this.pagina,
        cantidad_por_pagina: this.por_pagina,

      }
      const {data} = await this.services.catalogos.getEvaluaciones(this.$route.params.id, params)
      let evaluaciones = data.evaluaciones

      const evaluacionesFinal = evaluaciones.map(e => {
        if (e.porcentaje_relativo == 0) {
          e.porcentaje_relativo = null;
        }

        return e;
      });

      for (let i = 0; i < evaluaciones.length; i++) {
        const objeto = evaluaciones[i];
        this.$set(objeto, 'ley', false);

        if ('nombre_evaluacion_ley' in objeto) {
          // Agregamos la propiedad con el nuevo nombre "nombre"
          this.$set(objeto, 'nombre', objeto.nombre_evaluacion_ley);

          // Modificamos la propiedad ley para identificarla
          this.$set(objeto, 'ley', true);

          // Eliminamos la propiedad con el nombre antiguo
          delete objeto['nombre_evaluacion_ley'];
        }
      }
      
      this.evaluacion = evaluacionesFinal;
      this.pagina = Number(data.meta.pagina)
      this.por_pagina = Number(data.meta.cantidad_por_pagina)
      this.totalRegistros = Number(data.meta.totalEvaluaciones)
    },
    numItem(index) {
      const numero = this.por_pagina * (this.pagina - 1) + index
      return numero
    },
    async editarItem(item) {
      this.item = item;
      this.action = 'editar'
      this.formEvaluacion.pruebaRealizar = item.nombre;
      if (this.rol === 'unidad') {
        this.formEvaluacion.porcentajePrueba = (Number(item.porcentaje_relativo));
      } else if (this.rol === 'rrhh') {
        if(item.ley !== true){
          this.formEvaluacion.porcentajePrueba = (Number(item.porcentaje_total));
        }else{
          this.formEvaluacion.porcentajePrueba = (Number(item.porcentaje));
        }
      }
      this.formEvaluacion.descripcion = item.descripcion;
    },
    textBtn(rol) {
      const textBtn = {
        rrhh: this.item ? 'Actualizar' : 'Agregar',
        unidad: this.item ? 'Actualizar' : 'Agregar',
      }
      return textBtn[rol]
    },
    onPasteInteger (evt) {
      let pasted = evt.clipboardData.getData('text');

      let regex = /^[0-9]+$/g.test(pasted);
      if(regex == false){
        evt.preventDefault()
      }

      if(pasted.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu)){
        evt.preventDefault()
      }
    },
    async changePagina(pagina){
      this.pagina = pagina
      await this.getEvaluacion()
    },
    isPorcentajeTotalValido() {
      return this.totalPorcentaje;
    },
  },
  computed: {
    modifiedTableData() {
      return this.desserts.map(this.addCorrelativo);
    },
    exceptUnidad() {
      let isRRHH = this.rol !== 'unidad' ? true : false;
      return isRRHH
    },
    exceptEvaluacion() {
      let isExcept = this.rol !== 'unidad' && this.rol !== 'rrhh'? true : false;
      return isExcept
    },
    isReadonly(){
      if(this.rol === 'rrhh'){
        if(this.item !== null){
          if(this.item.hasOwnProperty('agregado_rrhh')){
            return this.exceptEvaluacion || this.item.agregado_rrhh !== true
          }else{
            return true
          }
        }else{
          return false
        }
      }else if (this.rol === 'unidad') {
        return this.exceptEvaluacion
      }else{
        return true
      }
    },
    total() {
      return Math.ceil(this.totalRegistros / this.por_pagina)
    },
    totalPorcentaje() {
      if (this.rol === 'unidad') {
        if (this.item && this.action === 'editar') {
          return this.evaluacion.reduce((total = 0, item) => {
            if(item.ley !== true){
              return total + Number(item.porcentaje_relativo)
            }
          }, 0) - this.item.porcentaje_relativo;
        } else {
          return this.evaluacion.reduce((total = 0, item) => {
            if(item.ley !== true){
              return total + Number(item.porcentaje_relativo)
            }
          }, 0);
        }
      } else if (this.rol === 'rrhh') {
        if (this.item && this.action === 'editar') {
          if(this.item.ley !== true){
            return this.evaluacion.reduce((total = 0, item) => {
              if(item.ley !== true){
                return total + Number(item.porcentaje_total)
              }else{
                return total + Number(item.porcentaje)
              }
            }, 0) - this.item.porcentaje_total;
          }else{
            return this.evaluacion.reduce((total = 0, item) => {
              if(item.ley !== true){
                return total + Number(item.porcentaje_total)
              }else{
                return total + Number(item.porcentaje)
              }
            }, 0) - this.item.porcentaje;
          }
        } else {
          return this.evaluacion.reduce((total = 0, item) => {
            if(item.ley !== true){
              return total + Number(item.porcentaje_total)
            }else{
              return total + Number(item.porcentaje)
            }
          }, 0)
        }
      }
    },
    pruebaRealizarErrors() {
      const errors = []
      if (!this.$v.formEvaluacion.pruebaRealizar.$dirty) return errors
      !this.$v.formEvaluacion.pruebaRealizar.required && errors.push('Prueba técnica es obligatorio')
      !this.$v.formEvaluacion.pruebaRealizar.maxLength && errors.push('Máximo 50 caracteres')
      !this.$v.formEvaluacion.pruebaRealizar.esAlphanumericoConTildes && errors.push('Solo se permiten caracteres alfabéticos')
      return errors
    },
    porcentajePruebaErrors() {
      const errors = []
      if (!this.$v.formEvaluacion.porcentajePrueba.$dirty) return errors
      !this.$v.formEvaluacion.porcentajePrueba.required && errors.push('Porcentaje es obligatorio')
      !this.$v.formEvaluacion.porcentajePrueba.numeric && errors.push("Porcentaje solo debe contener números");
      !this.$v.formEvaluacion.porcentajePrueba.minValue && errors.push("El valor tiene que ser mayor que 0");
      !this.$v.formEvaluacion.porcentajePrueba.maxValue && errors.push("El valor tiene que ser menor que 100");
      // !this.$v.formEvaluacion.porcentajePrueba.isComplete && errors.push("El valor se excede del porcentaje total");
      return errors
    },
    descripcionErrors() {
      const errors = []
      if (!this.$v.formEvaluacion.descripcion.$dirty) return errors
      !this.$v.formEvaluacion.descripcion.required && errors.push('Descripción es obligatorio')
      !this.$v.formEvaluacion.descripcion.maxLength && errors.push('Máximo 500 caracteres')
      !this.$v.formEvaluacion.descripcion.esAlphanumericoyCaracterEspecial && errors.push('Ingreso un caracter no permitido')
      return errors
    },
  },
  watch: {
    desserts: {
      handler(newItems) {
        // Realizar acciones cuando el array 'items' cambie
      },
      deep: true // Observar cambios profundos en los objetos del array
    },
    evaluacion: function (nuevo, anterior) {
      if(nuevo.length === 0 && this.numPage !== 1){
        this.numPage= 1
      }
    }
  },
  async created() {
    await this.init()
  }
}
</script>

<style scoped lang="scss">

</style>

<style scoped>
.inputNumber >>> input[type="number"] {
  -moz-appearance: textfield;
}

.inputNumber >>> input::-webkit-outer-spin-button,
.inputNumber >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

:deep(tbody tr:nth-of-type(odd)) {
  background-color: #eeeeee
}

</style>
