import {http_client} from "@/plugins/http_client";

const url = '/api/v1/contratacion'
const urldocumentos = 'api/v1/documentos'

const contratacion = async (params = {}) => await http_client(`${url}/asignar-establecimiento-proceso`, params, 'post')
const getcontrataciones = async (params = {}) => await http_client(`${url}/establecimiento-proceso-contratacion`, params, 'get')
const updateContratacion = async (params = {}, id) => await http_client(`${url}/asignar-establecimiento-proceso/${id}`, params, 'put')
const getcontratacionById = async (id, params = {}) => await http_client(`${url}/establecimiento-proceso-contratacion/${id}`, params, 'get')
const deleteContratacion = async (id, params = {}) => await http_client(`${url}/asignar-establecimiento-proceso/${id}`, params, 'delete')
const generarDocumentos = async (params = {}) => await http_client(`${url}/documento/generador`, params, 'post')
const GetDocumentoById = async (id,id2,params = {}) => await http_client(`${urldocumentos}/contratacion/${id}/tipo/${id2}`,params,'get' )

export default {
    contratacion,
    getcontrataciones,
    updateContratacion,
    getcontratacionById,
    deleteContratacion,
    generarDocumentos,
    GetDocumentoById
}
