<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr style="height: 50px">
          <th class="text-center font-weight-bold primary--text text-subtitle-2" v-for="(item) in headers"
            :key="item.value">
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.id" :style="setBackground(index)">

          <template v-for="(header) in headers">
            <td :key="header.value" class="text-center">
              <slot :name="`item.${header.value}`" :item="item">{{
            item[header.value]
          }}</slot>

            </td>
          </template>

        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>

import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  components: {

  },
  name: 'DocumentTable',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    headers: {
      type: Array,
      default: () => {
        return []
      },
    }

  },
  data() {
    return {};
  },

  methods: {
    closeDialog() {
      this.dialog_rechazar = false;
    },
    submit() {
      this.$emit("submit");
    },
    setBackground(index) {
      if (index % 2 === 0) {
        return {
          'background-color': '#ededf1'
        }
      }
      return {
        'background-color': 'white'
      }
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    dialog_rechazar: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {},
  created() { },
};
</script>

<style lang="scss" scoped>

</style>