var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center",class:[
    `text-${_vm.heading ? _vm.heading : _vm.textHeader}`,
    `mb-${_vm.margin}`,
    `font-weight-${_vm.weight}`,
    `d-flex ${_vm.flex}`,
    `text-${_vm.transform}`,
    `text-${_vm.alignment}`
    ],style:(_vm.textColor),domProps:{"textContent":_vm._s(_vm.text)}})
}
var staticRenderFns = []

export { render, staticRenderFns }