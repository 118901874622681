import Vue from "vue";
import store from "../store";

const http_client = async (
    url,
    data = {},
    method = "get",
    headers = {},
    sendToken = true
) => {
    const token = store.state.token
        ? store.state.token
        : localStorage.getItem("token");

    if (token && sendToken) headers.Authorization = "Bearer " + token;

    let config = {
        method,
        url,
        headers,
    };
    if (method === "get") {
        config.params = data;
    } else {
        config.data = data;
    }
    try {
        return await Vue.prototype.axios(config);
    } catch (e) {
        if (e.response.status === 500) {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response?.data?.message
                    || e.response?.data?.err?.description
                    || e.response?.data?.description
                    || "Ha ocurrido un error interno",
                type: "error",
            });
        } else if (e.response.status === 422) {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response?.data?.message
                    || e.response?.data?.err?.description
                    || e.response?.data?.description
                    || "No se pudo procesar la entidad",
                type: "warning",
            });
        } else if (e.response.status === 404) {
            let error = '';
            if (typeof e.response.data?.message !== "undefined") {
                error = e.response.data?.message;
            } else if (typeof e.response.data.error !== "undefined") {
                error = e.response.data.error;
            } else if (typeof e.response.data.description !== "undefined") {
                error = e.response.data.description;
            } else if (typeof e.response.data.err.description !== "undefined") {
                error = e.response.data.err.description;
            }
            Vue.prototype.temporalAlert({
                show: true,
                message: error
                    || "No se encontró el recurso",
                type: "warning",
            });
        } else if (e.response.status === 403) {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response.data?.message
                    || e.response.data.err.description  
                    || e.response.data.description
                    || "Petición rechazada",
                type: "warning",
            });
        } else if (e.response.status === 400) {
            let error = '';
            if (typeof e.response.data?.message !== "undefined") {
                error = e.response.data?.message;
            } else if (typeof e.response.data.error !== "undefined") {
                error = e.response.data.error;
            } else if (typeof e.response.data.description !== "undefined") {
                error = e.response.data.description;
            } else if (typeof e.response.data.err.description !== "undefined") {
                error = e.response.data.err.description;
            }
            Vue.prototype.temporalAlert({
                show: true,
                message: error
                    || "Petición errónea",
                type: "warning",
            });
        } else if (e.response.status === 401) {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response?.data?.message
                    || e.response?.data?.err?.description
                    || e.response?.data?.description
                    || "Acceso no autorizado",
                type: "warning",
            });
        }else if(e.code === 'ERR_NETWORK'){
            Vue.prototype.temporalAlert({
                show: true,
                message: "Se tienen problemas de conexión para cargar el recurso solicitado",
                type: "error",
            });
        } else {
            Vue.prototype.temporalAlert({
                show: true,
                message: e.response?.data?.message
                    || e.response?.data?.err?.description
                    || e.response?.data?.description
                    || "Error al realizar petición",
                type: "error",
            });
        }
        return e.response;
    }
};

Vue.prototype.http_client = http_client;

export {http_client};
