<template>
    <v-dialog persistent v-model="visible" max-width="700px" class="rounded-xl" >
      <v-card class="rounded-xl">>
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon color="gray" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
  
          <v-card-text style="word-break: normal;"
            class="text-center primary--text"        
          >
            <br />
            <p :class="dinamicClass1" class="font-weight-black azulPrimary--text">
              {{ title }}
            </p>
            <p :class="dinamicClass2" class="font-weight-bold">
              {{ label }}
            </p>
            <p :class="dinamicClass2">
              {{ label2 }}
            </p>
          </v-card-text>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              class="text-capitalize px-5 mr-3 "
              color="primary"
              rounded
              outlined
              @click="closeDialog"
            >
              Cancelar
            </v-btn>
            <v-btn
              large
              class="text-capitalize px-6 ml-3"
              color="primary"
              depressed
              dark
              rounded
              @click="guardarAlgo"
            >
              {{ labelBtn }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <br /><br />
        </v-card>    
    </v-dialog>
  </template>
  
  <script>
    export default {
      name: 'Dialogo',
      props: {
        visible: {
          type: Boolean
        },
        sendCod: {
          type: Boolean,
          default: false
        },
        cod:{
          type: String,
          default: "000"
        },
        title: {
          type: String,
          default: "Enviar solicitud"
        },
        label: {
          type: String,
          default: "¿Desea enviar la solicitud a la unidad solicitante?"
        },
        label2: {
          type: String,
          default: "¡No podrá deshacer este cambio!"
        },
        labelBtn:{
          type: String,
          default: "Aceptar"
        }
      },
      methods: {
        closeDialog() {
          this.$emit('close');
        },
        guardarAlgo(){
          this.$emit('guardar');
        }
      },
      computed: {
        dinamicClass1() {
          switch (this.$vuetify.breakpoint.name) {
            case "xs":
            return "text-h4";
          case "sm":
            return "text-h3";
          case "md":
            return "text-h3";
          case "lg":
            return "text-h3";
          case "xl":
            return "text-h3";
          case "2xl":
            return "text-h3";
          }
        },
        dinamicClass2() {
          switch (this.$vuetify.breakpoint.name) {
            case "xs":
              return "text-h6 pt-4";
            case "sm":
              return "text-h5 pt-4";
            case "md":
              return "text-h5";
            case "lg":
              return "text-h5";
            case "xl":
              return "text-h5";
            case "2xl":
              return "text-h5";
          }
        },
    },
    }
  </script>
  
  <style lang="scss" scoped>
    ::v-deep .v-dialog {
      border-radius: 75px; /* Cambia el valor según tus preferencias */
    }
  </style>