
export default {
  namespaced: true,
  state: {
   fases: {
     UNIDAD: 1,
     RRHH: 2,
     UFI: 3,
     DESPACHO: 4
   },
    faseID: {
      1: 'Borrador',
      2: 'Completado',
      3: 'A solventar',
      4: 'Pendiente',
      5: 'Rechazado',
      6: 'Observado',
      7: 'Aprobado'
    }
  },
  getters: {
    getFase: (state) => (fase) => state.fases[fase],
    getFaseID: (state) => (id) => state.faseID[id],
  },
  mutations: {

  },
  actions: {

  },
};


