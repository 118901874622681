import Vue from "vue";
import institucionesServices from "@/services/instituciones.services";
import establecimientosServices from "@/services/establecimientos.services";
import unidadesOrganizativasServices from "@/services/unidadesOrganizativas.services";
import iconosServices from "@/services/iconos.services";
import fasesServices from "@/services/fases.services";
import plantillas from "@/services/plantillas.services";
import vue from "vue";

export default {
    namespaced: true,
    state: {
        id_proceso: null,
        instituciones: [],
        establecimientos: [],
        unidadesOrganizativas: [],
        documentos: [],
        existeUltimaFase: false,
        iconos: [],
        pagination: {
            totalPages: null,
            page: 1,
        },
       id_fase:null,
        objeto: {
            items: [],
            headers: [
               
                {
                    text: "Nombre de la fase",
                    align: "left",
                    sortable: false,
                    value: "nombre",
                    class: "no-border font-weight-black text-subtitle-1",
                },
                {
                    text: "Fase final",
                    align: "center",
                    sortable: false,
                    value: "faseFinal",
                    class: "no-border font-weight-black text-subtitle-1",
                },
                {
                    text: "Institución",
                    align: "left",
                    sortable: false,
                    value: "institucion.nombre",
                    class: "no-border font-weight-black text-subtitle-1",
                },
                {
                    text: "Establecimiento",
                    align: "left",
                    sortable: false,
                    value: "establecimiento.nombre",
                    class: "no-border font-weight-black text-subtitle-1",
                },
                {
                    text: "Unidad",
                    align: "left",
                    sortable: false,
                    value: "unidad_organizativa.nombre",
                    class: "no-border font-weight-black text-subtitle-1",
                },
                {
                    text: "Icono",
                    align: "center",
                    sortable: false,
                    value: "icono",
                    class: "no-border font-weight-black text-subtitle-1",
                },
                {
                    text: "Acciones",
                    align: "center",
                    sortable: false,
                    value: "acciones",
                    class: "no-border font-weight-black text-subtitle-1",
                },
            ],
        },
    },

    mutations: {
        setInstituciones(state, payload) {
            state.instituciones = payload;
        },

        setEstablecimientos(state, payload) {
            state.establecimientos = payload;
            state.unidadesOrganizativas = [];
        },

        setUnidadesOrganizativas(state, payload) {
            state.unidadesOrganizativas = payload;
        },

        setDocumentos(state, payload) {
            state.documentos = payload;
        },

        setIconos(state, payload) {
            state.iconos = payload;
        },

        setFases(state, payload) {
            state.objeto.items = payload;
            state.existeUltimaFase = state.objeto.items.some(item => item.ultimo === true);
        },
        setUltimaFase(state, payload) {
            state.existeUltimaFase = payload;
        },
        setPagination (state, payload) {
            Object.assign(state.pagination, payload);
        }
    },

    actions: {
        async getInstituciones( { commit, state } ) {
            try {
                const response = await institucionesServices.getInstituciones();
                commit('setInstituciones', response.data.data);
            } catch (error) {}
        },

        async getEstablecimientos( { commit, state }, payload ) {
            try {
                const response = await establecimientosServices.getEstablecimientos( payload )
                commit('setEstablecimientos', response.data.data);
            } catch (error) {}
        },

        async getUnidadOrganizativa( { commit, state }, payload ) {
            try {
                const response = await unidadesOrganizativasServices.getUnidades(payload)
                commit('setUnidadesOrganizativas', response.data.data);
            } catch (error) {}
        },

        async getIcono( { commit, state }) {
            try {
                const response = await iconosServices.getIconos()
                commit('setIconos', response.data.data);
            } catch (error) {}
        },

        async getFases( { commit, state } )  {
            try {
                const response = await  fasesServices.getFases( state.id_proceso, { page: state.pagination.page, order: 'asc' } )
                const { data, totalPages } = response?.data;
                commit('setFases', data);
                totalPages === 1 && commit('setPagination', { totalPages, page: totalPages });
                commit('setPagination', { totalPages });
            } catch (error) { }
        },

        async getDocumentos({ commit }, nombrePlantilla = {}) {
            try {
                const response = await plantillas.getPlantilla({...nombrePlantilla, pagination: false })
                if (response?.status !== 200) {
                    Vue.prototype.temporalAlert({
                        show: true,
                        message: 'No se pudo obtener los documentos',
                        type: 'error'
                    });

                    return
                }

                commit('setDocumentos', response?.data);
            } catch ( err ) { }
        },
    }
}