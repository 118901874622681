import {http_client} from "@/plugins/http_client";

const url = '/api/v1/solicitud'
const getSolicitudes = async (params = {}) => await http_client(`${url}`, params, 'get')
const getFases = async (numFase, params = {}) => await http_client(`${url}/fase/${numFase}`, params, 'get')
const getObservaciones = async (id_solicitud, params = {}) => await http_client(`${url}/${id_solicitud}/observaciones`, params, 'get')
const updateSolicitud = async (id_solicitud, params = {}) => await http_client(`${url}/update_state/${id_solicitud}`, params, 'put')

const getSolicitudesByFase = async (id_fase,params = {}) => await http_client(`${url}/aprobacion`, params, 'get')
const updateMoveFase = async (id_fase,params = {}) => await http_client(`${url}/mover-fase/${id_fase}`, params, 'put')
const updateEstadoFase = async (id_fase,params = {}) => await http_client(`${url}/evaluar/${id_fase}`, params, 'put')
const sendSolicitud = async (params = {}) => await http_client(`${url}/iniciar-proceso`, params, 'post')
const putEnviarFase = async (idSoli, body) => await http_client(`${url}/mover-fase/${idSoli}`, body, 'put')
const getSolicitudesFase = async (id_fase, params = {}) => await http_client(`${url}/fase/${id_fase}`, params, 'get')
const getBitacora = async (id_solicitud, params = {}) => await http_client(`${url}/bitacora/${id_solicitud}`, params)
const getPuestoRequisito = async (id_solicitud, params = {}) => await http_client(`${url}/puesto-requisito/${id_solicitud}`, params)
const postPuestoRequisito = async (id_solicitud, params = {}) => await http_client(`${url}/${id_solicitud}/puesto-requisito`, params, 'post')
const deletePuestoRequisito = async (id_solicitud, params = {}) => await http_client(`${url}/${id_solicitud}/puesto-requisito`, params, 'delete')
const getConocimientosYExperiencias = async (id_solicitud, params = {}) => await http_client(`${url}/${id_solicitud}/puesto-requisito`, params, )
const sugerenciaProcesoReclutamiento = async (id_fase,params = {}) => await http_client(`${url}/aprobacion/${id_fase}`, params, 'get')
const getSolicitudesAprobacionByFase = async (id_fase,params = {}) => await http_client(`${url}/aprobacion/${id_fase}`, params, 'get')




export default {
  getSolicitudes,
  getSolicitudesByFase,
  updateMoveFase,
  updateEstadoFase,
  updateSolicitud,
  sendSolicitud,
  getSolicitudesFase,
  getFases,
  putEnviarFase,
  getObservaciones,
  getBitacora,
  getPuestoRequisito,
  postPuestoRequisito,
  deletePuestoRequisito,
  getConocimientosYExperiencias,
  getSolicitudesAprobacionByFase,
  sugerenciaProcesoReclutamiento,
}
