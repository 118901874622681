import {http_client} from "@/plugins/http_client";

const url = '/api/v1/proceso-contratacion'
const url1 = '/api/v1'

const getCandidatosProceso = async (id_proceso,params={})=> http_client(`${url}/${id_proceso}/contratacion`,params,'get')
const getFasesAprobacion = async (id_fase,params={})=>http_client(`${url}/${id_fase}/contratacion-fase-aprobacion`,params,'get')
const postPrimeraFase = async(params={})=>http_client(`${url1}/contratacion/asignar_contratacion_fase`,params,'post')
const postPasarFases = async(params={})=>http_client(`${url}/mover-fase`,params,'post')
const getPlantillasFaseInicial = async (id_proceso,params={})=> http_client(`${url}/${id_proceso}/fase-inicial/plantilla`,params,'get')
const getPlantillasFaseSiguiente = async (id_fase,params={})=> http_client(`${url}/${id_fase}/fase-siguiente/plantilla`,params,'get')

export default {
    getCandidatosProceso,
    getFasesAprobacion,
    postPrimeraFase,
    postPasarFases,
    getPlantillasFaseInicial,
    getPlantillasFaseSiguiente,
}