<template>
    <v-form class="pt-4" ref="formFiltro" >
        <v-row>
            <v-col cols="12" md="3">
                <v-text-field
                    v-model="filter.codigo"
                    :item-text="filterCodigo.text"
                    :item-value="filterCodigo.value"
                    outlined
                    class="rounded-xl"
                    label="Código"
                    @input="filtrarCodigo(true)"
                    :error-messages="filterCodigoErrors"
                    clearable
                    @click:clear="filter.codigo = ''; $emit('filtrar')"
                    maxlength="51"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field v-model="filter.nombre" :label="filterTexto" outlined class="rounded-xl" clearable
                              :error-messages="filterNombreErrors" maxlength="51"
                              @keyup="filterNombre($event, true)"
                @click:clear="filter.nombre = null; $emit('filtrar')"/>
            </v-col>
            <v-col cols="12" md="4">
                <v-autocomplete
                :search-input.sync="btext"
                v-model="filter.estado"
                :items="filterEstado.items"
                :item-text="filterEstado.text"
                :item-value="filterEstado.value"
                :label="filterEstado.label"
                outlined
                @keyup="buscar(true)"
                clearable
                @click:clear="filter.estado = null; $emit('filtrar')"
                @change="filtrarEstado()"
                class="rounded-xl">

                <template v-slot:item="{ item }">
                    <v-list-item-content>
                        <v-list-item-title class="custom-item-class">{{ item.nombre }}</v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="5" md="2" lg="1" class="d-flex flex-column flex-md-row" >
                <div class="d-flex justify-center">
                    <v-btn min-width="100" width="35vw" max-width="120" class="lowercase-text pl-5 pr-5 d-block mt-md-0 " color="primary" style="color: #fff; text-transform: none !important;" rounded :style="{ height: '46px' }"
                        @click="$refs.formFiltro.reset(), $emit('filtrar')">
                        <v-icon left>mdi-filter-off-outline</v-icon>
                        Limpiar
                    </v-btn>
                </div>
            </v-col>

        </v-row>
        <v-row  justify="end" class="pb-3" color="primary" >
            <v-col cols="12" sm="4" md="2" lg="2" class="text-right">
                <slot name="btnAccion" class="btn-text"></slot>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import {maxLength, helpers} from "vuelidate/lib/validators";

export default {
    props: {
        filterCodigo:{
            type: Object,
            default: () =>({
                text:'',
                value: '',
            })
        },
        filterEstado:{
            type: Object,
            default:()=>({
                items:[],
                text:'nombre',
                value:'id',
                label: ''
            })
        },
        filterTexto:{
            type: String,
            default: 'Nombre'
        },
        refPadre:{
            type: Object,
            default: ()=>{}
        },

        useInputCode: {
            type: Boolean,
            default: true
        },
    },
    data: ()=> ({
        filter:{
            codigo:null,
            nombre:'',
            estado:null,
        },
      btext:'',
      codigo: true,
      nombre: false,
    }),
    methods: {
      async buscar(typing = false) {
        if (typing) {
          await this.timeout()
        }
        if (this.btext.length > 3) {
            this.$emit('buscar', this.btext)
        }

      },
      async filtrarCodigo(typing = false) {
        if (typing) await this.timeout()
        this.$v.filter.codigo.$touch();
        if(this.codigo === true) {
          this.$emit('filtrar')
        }
      },
      filtrarEstado() {
        this.$emit('filtrar')
      },

      timeout(ms = 500) {
        if (this.searchTimeout) {
          clearInterval(this.searchTimeout)
        }
        return new Promise((resolve) => {
          this.searchTimeout = setTimeout(resolve, ms)
        })
      },
      clearInput(event) {
        this.filter.codigo = ''
        this.$emit('filtrar')
      },

      async filterNombre(event, typing = false) {
        if (typing) {
          await this.timeout()
        }

        if (this.filter.nombre.length > 3 || this.filter.nombre.length === 0 ) {
          if(this.nombre === true) {
            this.$emit('filtrar')
          }
        }
      },
    },
  validations() {
    const notAlphanumeric = (value) => {
      const alphanumericRegex = /[^a-zA-Z0-9ñÑ ]/;
      return !alphanumericRegex.test(value);
    };

    return {
      filter: {
        codigo: {
          maxLength: maxLength(50),
          notAlphanumeric: notAlphanumeric
        },
        nombre: {
          maxLength: maxLength(50),
          notAlphanumeric: notAlphanumeric
        }
      },
    }
  },
  computed: {
    filterCodigoErrors() {
      const errors = [];
      if (!this.$v.filter.codigo.notAlphanumeric)   errors.push("Solo se permiten caracteres alfanuméricos");
      if (!this.$v.filter.codigo.maxLength) errors.push("Máximo 50 caracteres");
      if(errors.length <= 0){
        this.codigo = true
      }else{
        this.codigo = false
      }
      return errors;
    },
    filterNombreErrors(){
      const errors = [];
      if (!this.$v.filter.nombre.notAlphanumeric) errors.push("Solo se permiten caracteres alfanuméricos");
      if (!this.$v.filter.nombre.maxLength) errors.push("Máximo 50 caracteres");
      if(errors.length <= 0 ){
        this.nombre = true
      }else{
        this.nombre = false;
      }
      return errors;
    }
  },
}
</script>

<style>
.custom-item-class {
    min-width: 100%;
    max-width: 185px;
}

</style>
