import {http_client} from "@/plugins/http_client";

const url = '/api/v1'

const getTipoContrato = async (params = {}) => await http_client(`${url}/tipo_contrato`, params, 'get')
const postProcesoContratacion = async (params = {}) => await http_client(`${url}/proceso-contratacion`, params, 'post')
const getProceso = async (id, params = {}) => await http_client(`${url}/proceso-contratacion/${id}`, params, 'get')
const updateProceso = async (id, params = {}) => await http_client(`${url}/proceso-contratacion/${id}`, params, 'put')

const getProcesos = async (params = {}) => await http_client(`${url}/proceso-contratacion`, params, 'get')
const getProcesosAsignacion = async (params = {}) => await http_client(`${url}/proceso-contratacion/instituciones_session`, params, 'get')
const getDatosUsuario = async (params = {}) => await http_client(`${url}/proceso-contratacion/establecimientos`, params, 'get')


export default {
    getTipoContrato,
    postProcesoContratacion,
    getProceso,
    updateProceso,
    getProcesos,
    getProcesosAsignacion,
    getDatosUsuario
}
