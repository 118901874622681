<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <div class="d-flex flex-wrap justify-center justify-sm-end" v-if="validRol(listRoles.solicitudes.evaluar)">
        <div :class="dataClass">
          <v-btn
          :disabled="FaseActiva !== 4"
          @click="$emit('input', {DialogRechazar:true})"
          color="red darken-1"
          class="btn-text ma-2 white--text"
          width="120"
          :small="small"
          rounded>
            Rechazar
          </v-btn>
        </div>
        <div :class="dataClass">
          <v-btn
          :disabled="FaseActiva !== 4 || observado"
          @click="$emit('input', {DialogObservar:true})"
          color="orange darken-1 "
          class="btn-text ma-2 white--text"
          width="120"
          :small="small"
          rounded>
            Observar
          </v-btn>
        </div>
        <div :class="dataClass">
          <v-btn
          :disabled="FaseActiva !== 4"
          @click="$emit('input', {DialogAutorizar:true})"
          color="primary"
          class="btn-text ma-2"
          width="120"
          :small="small"
          rounded>
            {{ isTheEnd ? 'Finalizar' : 'Aprobar' }}
          </v-btn>
      </div>
      </div>
    </v-col>    
  </v-row>  
</template>
<script>
export default {
  props: {
    FaseActiva:{
      type: Number,
      default:4
    },
    isTheEnd:{
      type: Boolean,
      default: false
    },
    small:{
      type: Boolean,
      default: true
    },
    dataClass:{
      type: String,
      default: 'ma-2 pa-2'
    },
    observado: {
      type: Boolean,
      default: false
    }
  }
}
</script>