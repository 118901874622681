import Vue from "vue";
import "./plugins/axios";
import "./plugins/http_client";
import "./plugins/utils";
import "./plugins/security"
import App from "./App.vue";
import router from "./router";
import './services'
import store from "./store";
import vuetify from "./plugins/vuetify";
import vMask from "v-mask";
import "./assets/scss/variables.scss";
import { VTextField } from "vuetify/lib";

import Vue2Editor from "vue2-editor";

const moment = require("moment");
require("moment/locale/es");
Vue.prototype.moment = moment;
import momentTime from "moment-timezone";

//formateo de dolares $
Vue.prototype.$currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

Vue.use(Vue2Editor)
Vue.config.errorHandler = function (err, vm, info) {
    console.error('Error:', err, 'Info:', info);
  };

import VCurrencyField from "v-currency-field";
Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
    locale: "es-US",
    decimalLength: 2,
    autoDecimalMode: true,
    min: 0,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false,
});


Vue.use(vMask);
Vue.use(require("vue-moment"), {
    moment,
    momentTime,
});

import loadComponentes from "@/plugins/loadComponents";

loadComponentes(Vue);

Vue.config.productionTip = false;

import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

// import CKEditor from '@ckeditor/ckeditor5-vue2';

// Vue.use( CKEditor );

new Vue({
    router,
    store,
    vuetify,
    beforeCreate() {
        const token = localStorage.getItem("token");
        if (token) store.commit("setToken", token);
    },
    render: (h) => h(App),
}).$mount("#app");
